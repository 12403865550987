	#pageOverlayLeft, #pageOverlayRight {
		display: none;
	}
	
	section .row {
		zoom: 1;     /* triggers hasLayout */
		}  /* Only IE can see inside the conditional comment
		and read this CSS rule. Don't ever use a normal HTML
		comment inside the CC or it will close prematurely. */
		
	
	.f-only {
		display: block !important;
	}
	
	.f-hide {
		display: none !important;
	}
	
/*  header elements  */

	#headerWrapper {
   		zoom: 1;
   		overflow: visible; 
   	}

	
	#headerWrapper {
		z-index: 100;
	}
	
	a#headerLogo img {
		height: 203px;
	}
	
	#nav {
		z-index: 100;
	}
	
	#mainFeatureContainer {
		&.contentHeightSpacer {
			max-height: 840px !important;
		}
	}
	
	#mainFeatureContainer #featureOverlay { height: 500px; }
	
	#mainFeatureContainer #featureContainer {
		margin: 0 auto;
		width: 1500px;
		
		img {
			display: block;
		}
		
	}
	
	#contentWrapper .sectionWrapper.featurePanel section.fullWidth {
		position: relative;
		width: 920px;
	}
	
	.introHeading {
		float: left !important;
		width: 470px !important;
		margin: 0 !important;
		top: auto;
		left: auto;
		right: auto;
	}
	.column.entryButton {
		float: right !important;
		width: 430px !important;
		margin: 0 !important;
		top: auto;
		left: auto;
		right: auto;
	}
	
	.introMainText {
		float: left !important;
		width: 470px !important;
		margin: 0 !important;
		top: auto;
		left: auto;
		right: auto;
	}
	.introLogos {
		float: right !important;
		width: 430px !important;
		margin: 0 !important;
		top: auto;
		left: auto;
		right: auto;
	}
	.sectionWrapper.featurePanel.LLF-yellow .bgColorWrapper.llfHotelBG section .row.hotelSpacer {
		margin-bottom: 0;
	}
