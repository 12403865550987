// ************************************************
// ************************************************
// *****                                      *****
// *****    SASS RESPONSIVE GRID VERSION 2    *****
// *****    --   LEGACY DESKTOP CSS   --      *****
// *****    --   www.semantic.co.uk   --      *****
// *****                                      *****
// ************************************************
// ************************************************

// *****  variables  *****

	$grid-width: 960;
	$gutter-width: 20;
	$column-width: 60;
	$column-count: 12;
	

// *****  functions  *****

	@function columnFixedSize($spanCount) {
  		@return (($spanCount * $column-width) + (($spanCount - 1) * $gutter-width));
	}

	@function rowFixedSize($colCount) {
  		@return (columnFixedSize($colCount) + $gutter-width);
	}

	@function offsetSize($spanCount) {
  		@return (columnFixedSize($spanCount) + ($gutter-width * 1.5));
	}

	@function rowMargin($spanCount) {
  		@return percentage(($gutter-width/2) / columnFixedSize($spanCount));
	}

	@function subRowColMargin($spanCount) {
  		@return percentage(($gutter-width/2) / rowFixedSize($spanCount));
	}
	
	
// *****  grid base elements  *****

	section {
		margin: 0px auto 0px auto;
		width: 960px;
		text-align: left;
	}
	.row {
		position: relative;
		margin: 0px -10px 0px -10px;
		padding: 0px;
	}
	.row:after {
		display: table;
		content: "";
		line-height: 0;
		clear: both;
	}
	.column {
		display: block;
		float: left;
		margin: 0px 10px 0px 10px; /* 10px of 960px */
	}

	
// *****  legacy desktop 'full' version  *****
	
		body:after {
			content: 'f';
			display: none;
		}
	
		// top-level rows
	
		@for $i from 1 through $column-count {
			// columns
			.f-span#{$i} {
				width: percentage(columnFixedSize($i)/rowFixedSize($column-count));
				margin: 0px 10px 0px 10px;
			}
		}
	
		@for $i from 1 through $column-count {
			// modifiers
			.f-offset#{$i} {margin-left: percentage(offsetSize($i)/rowFixedSize($column-count));}
			.f-push#{$i} {left: percentage((offsetSize($i) - ($gutter-width/2)) / rowFixedSize($column-count));}
			.f-pull#{$i} {right: percentage((offsetSize($i) - ($gutter-width/2)) / rowFixedSize($column-count));}
		}
		.f-clear {clear: both;}
		
		// sub-rows
	
		@for $i from 1 through $column-count {
			
			//row
			.f-span#{$i} .row {margin: 0px -#{rowMargin($i)} 0px -#{rowMargin($i)};}
			
			// columns
			@for $x from 1 through $i {
				.f-span#{$i} .row .f-span#{$x} {
					width: percentage(columnFixedSize($x)/rowFixedSize($i));
					margin: 0px subRowColMargin($i) 0px subRowColMargin($i);
				}
			}
			
			// modifiers
			@for $x from 1 through $i {
				.f-span#{$i} .row .f-offset#{$x} {margin-left: percentage(offsetSize($x)/rowFixedSize($i));}
				.f-span#{$i} .row .f-push#{$x} {left: percentage((offsetSize($x) - ($gutter-width/2)) / rowFixedSize($i));}
				.f-span#{$i} .row .f-pull#{$x} {right: percentage((offsetSize($x) - ($gutter-width/2)) / rowFixedSize($i));}
			}
		}