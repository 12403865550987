/*
******************************
*****  LARGE TABLET CSS  *****
******************************
*/
	
	.m-only {
    	display: none !important;
	}
	.st-only {
    	display: none !important;
	}
	.lt-only {
    	display: inherit !important;
	}
	.f-only {
    	display: none !important;
	}
	
	.st-hide {
    	display: inherit !important;
	}
	.inline.st-hide {
    	display: inline !important;
	}
	
	.lt-hide {
    	display: none !important;
	}
	
	.st-show {
    	display: none;
	}
	.lt-show {
    	display: inherit !important;
	}
	.inline.lt-show {
    	display: inline !important;
	}

	.m-padded {
		padding: 0px;
	}
	.st-padded {
		padding: 0px;
	}
	.lt-padded {
		padding: 15px 15px 5px 15px;
	}
	
	
	.extraTopMargin {
		margin-top: 55px !important;
	}
	h3.extraTopMargin {
		margin-top: 35px !important;
	}
	.row.extraBottomMargin {
		margin-bottom: 40px !important;
	}
	.mobileAlignCenter {
		text-align: left;
	}
	
/*
Header layout
------------------
*/
	#headerWrapper {
	}
	
	.logoFadeImage {
		display: none;
	}

/*
Main features
------------------
*/	
	#topBGImageContainer {
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
				
		#topBGImageOverlay {
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			height: 150px;
			
			/* IE9 SVG, needs conditional override of 'filter' to 'none' */
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFkMTc0YyIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxZDE3NGMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
			background: -moz-linear-gradient(top,  rgba(29,23,76,0) 0%, rgba(29,23,76,1) 100%); /* FF3.6+ */
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(29,23,76,0)), color-stop(100%,rgba(29,23,76,1))); /* Chrome,Safari4+ */
			background: -webkit-linear-gradient(top,  rgba(29,23,76,0) 0%,rgba(29,23,76,1) 100%); /* Chrome10+,Safari5.1+ */
			background: -o-linear-gradient(top,  rgba(29,23,76,0) 0%,rgba(29,23,76,1) 100%); /* Opera 11.10+ */
			background: -ms-linear-gradient(top,  rgba(29,23,76,0) 0%,rgba(29,23,76,1) 100%); /* IE10+ */
			background: linear-gradient(to bottom,  rgba(29,23,76,0) 0%,rgba(29,23,76,1) 100%); /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001d174c', endColorstr='#1d174c',GradientType=0 ); /* IE6-8 */
		}
	}
	
/*
General layout
------------------
*/
		
	section .withBottomMargin {
		display: block;
		margin: 0px 0px 15px 0px;
	}
	
	.modalVideoContainer {
		min-width: 620px;
		margin-bottom: 0px;
	}
	

	// paragraph styles 
	p {
		font-size: 1.15em;
		
		&.largeText {
			font-size: 1.25em !important;
		}
	}
	ul, ol {
		font-size: 1.15em;
	}
	
	ul {
		&.perksList {	
			li {
				float: left;
				margin: 0px 1.5%;
				text-align: center;
				width: 30%;
			}
			
			&.perksBoxes {
				li {
					margin: 0px 0px 10px 0px;
					width: 98%;
				}				
			}
		}
	}
		
	h1 {
		font-size: 35px;
		line-height: 43px;
		margin: 0px 0px 15px 0px;
	}
	
	h2 {
		font-size: 29px;
		line-height: 38px;
		margin: 15px 0px 15px 0px;
	}
	
	h3 {
		font-size: 24px;
		line-height: 32px;
		margin: 35px 0px 15px 0px;
	}
	
	h4 {
		font-size: 21px;
		line-height: 27px;
	}


	.sectionWrapper {
		&.featurePanel {
			margin: 0 -3%;
			width: 106%;
			
			//overall feature styling
			.featurePanelMobileImage {
				float: right;
				margin: 2.5% 2.5% 2.5% 0.5%;
				width: 40%;
				
				img {
					width: 220%;
					max-width: none;
				}
			}
			
			//which side is the content?
			&.contentRight {
				.featurePanelMobileImage {
					float: left;
					margin: 2.5% 0.5% 2.5% 2.5%;
				}
				section {
					float: right;
					margin: 0 2.5% 0 0;
					width: 52.5%;
				}
			}
						
		}
	}	


/*
Main features
---------------------------
*/
	
	#mainFeatureContainer {
		#featureContainer {
			
			img {
				margin: 140px 0px 0px 0px;
				max-width: 100%;
				width: 100%;
			}

			.bg-video {
				height: 56.25vw;
				iframe {
					width: 100%;
					height: calc(100vw * .5625);
				}
			}
		}
	}


/*
Misc
------------------
*/
	
	.googleMap, .googleMap iframe {
		width: 100%;
		height: 350px;
	}
	
	ul.optionButtons {
		margin: 0px 0px 5px 0px;
		padding: 0px 0px 0px 0px;
		li {
			display: inline-block;
			margin: 0px 10px 10px 0px;
			padding: 0px 0px 0px 0px;
			a {
				margin: 0px 0px 0px 0px !important;
			}
		}
	}
	
	/*.imageContainer {
		margin: 0px 0px 0px 0px;
		.pictureShadow {
			max-width: 426px;
		}
	}*/



/*
Attraction listings
------------------
*/
ul.attractionListing, ul.attractionListingSinglePack {
	li {

		.attractionLink {
			height: auto;
		}

		&:nth-child(2n) {
			margin-right: 2%;
		}

		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	&.smallItems {
		li {
			width: 10%;
		}
	}
}

.panel.activeAttractionBox {
	.panelContent {
		margin-left: auto;
		margin-right: auto;
		width: 88%;
	}
    .panel.priceTypesContainer {
	    margin: 0px auto;
	    width: 100%;
	
        .panel {
            .panelContent {
                padding: 20px 15px 20px 15px;

	            h2 {
                    font-size: 32px;
                    line-height: 25px;
		            margin: 20px 0px 25px 0px !important;
		            text-transform: none;
		            
		            &.largeHeading {
		            	margin: 35px 0px 25px 0px !important;			            
		            }
	            }
	            
	            .priceConfirmArea {
		            p {
			            margin-top: 0px !important;
		            }
	            }
            }
        }
    }
	
	.gugfLogoSmall {
		margin-top: -25px;
	}
	
    &.legolandWindsor, &.legolandDiscoveryCentre {
        .panelContent {
            .attrSideLogo {
                margin-left: -10%;

                img {
                    margin-bottom: 25px;
                    width: 90%;
                    max-width: 400px;
                    max-height: none;
                }
            }
        }
    }
}



/*
Panel layout
------------------
*/

	.panel {		
		
		.panelContent {
			padding: 1.9%;
		
			h1, h2, h3, h4, p, ul, ol {
				color: $color-dark-blue;
			}
			h2 {
				font-size: 1.5em;
			}
			h3 {
				font-size: 1.35em;
			}
			p, ul, ol {
				font-size: 1em;
				margin: 5px 0px 15px 0px;
			}
			h2, h3 {
				&:first-child {
					margin-top: 0px;
				}
			}				
		}
		
		
		&.attractionPanel {
			.panelContent {
	
				h2 a {
					color: $color-dark-blue;
					text-decoration: none;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		
	}


/*
Footer layout
------------------
*/
/* Footer menu */
	footer dl.footerLinksColumn {
		dd {
			display: inline;
		    margin: 0 10px 3px 10px;
		    padding: 0;
		}
	}

	footer section .row .column {
		text-align: center;
	}

	#footerWrapper {
		#footerDecoration {
			footer {
				p {
					text-align: center;
					
					&.smallText {
						width: 100%;
						
						.blockOut {
							display: block;
						}
					}
				}
			}
			.merlinLogoArea {
				position: absolute;
				bottom: 0px;
				right: 0px;
				width: 275px;
			}
		}
	}

/*
Step-by-step guide styles 
---------------------------
*/
/* Panel 1 */
.circlePanel {
	&.circlePanelOne {
		margin: 0 0 0 -160px;
		.orbImages img {
			width: 100px;
		}
	}
	&.circlePanelTwo {
		margin: 0 -160px 0 0;
		.orbImages {
			.kraveOne {
				position: absolute;
				top: 20px;
				left: 25px;
				width: 80px;
			}
			.kraveTwo {
				position: absolute;
				top: 50px;
				left: 140px;
				width: 100px;
			}
			.kravePackaging {
				position: absolute;
				top: 20px;
				left: 270px;
				width: 200px;
			}
		}
	}
	&.circlePanelThree {
		margin: 0 0 0 -160px;
		.orbImages {
			img {
				width: 130px;
			}
			.mtOrb {
				position: absolute;
				top: 0;
				left: 20px;
			}
			.shrekOrb {
				position: absolute;
				top: 80px;
				left: 160px;
			}
			.atrOrb {
				position: absolute;
				top: 30px;
				left: 310px;
			}
		}
	}
}

body.homepage.asda {
    .row.orbs {
        #orb1, #orb5 {
            margin-top: -80px;
        }
        #orb3 {
            margin-top: 50px;
        }
    }
}
/* Short Breaks Section */

.logosContainer {
    .shortBreakLogo {
        display: inline-block;
        width: 85px;
        margin: 5px;
        padding: 2px;
        border-radius: 5px;
        background: $attr-wc-bg;
    }
}
