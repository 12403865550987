// Asda page variables
$font-heading-asda:	'Luckiest Guy', cursive;

$font-body:		'Signika', sans-serif;
$font-heading:	'Signika', sans-serif;
$font-icon:		'FontAwesome';

$color-white: #fff;
$color-black: #000;

$color-dark-blue: #1d174c;
$color-light-blue: lighten(#1d174c, 30%);
$color-pink: #e800ff;
$color-purple: #6a3996;
$color-darker-purple: #1d174c;
$color-dark-purple: #1a0032;
$color-yellow: #ffea00;
$color-green: #3f9212;
$color-blue: #005991;

$color-red: #f10217;
$color-dark-red: #380706;
$color-orange: #e38a23;

$color-purple-faded: #8d7499;

$color-grey: #a8a7b4;
$color-lighter-grey: #e5e5e5; 

$color-tab-off: #aaa;
$color-tab-on: #009ee0;

$color-form-error-style: lighten($color-pink, 20%);

$color-rip-panel-blue: #3169F5;

// Link colors above to shared elements
$body-bg-color: $color-dark-blue;

$text-color: $color-white;
$text-main-heading-color: $color-white;
$text-heading-color: $color-white;

$link-color: lighten($color-purple, 33%);
$link-hover-color: $color-pink;
$link-visited-color: lighten($color-purple, 33%);

$breadcrumb-color: lighten($color-purple, 33%);
$breadcrumb-link-color: lighten($color-purple, 33%);
$breadcrumb-link-hover-color: $color-white;

$list-style-color: $color-purple;

$footer-bg-color: darken($color-dark-blue, 10%);
$footer-text-color: lighten($color-orange, 25%);
$footer-link-color: lighten($color-orange, 25%);
$footer-link-hover-color: #ffffff;

$modal-bg: #04032a;


//attraction feature colors
$attr-atr-bg: #2b1456;
$attr-atr-text: $color-white;
$attr-atr-highlight: $color-orange;
$attr-atr-panel-text: $color-white;
$attr-atr-panel1-bg: #e71a86;
$attr-atr-panel2-bg: #6d09c2;
$attr-atr-summary-cta: $attr-atr-highlight;
$attr-atr-summary-cta-text: $attr-atr-text;
$attr-atr-summary-text-color: $attr-atr-text;
$attr-atr-summary-heading-bg: darken($attr-atr-bg, 14%);
$attr-atr-summary-text-bg: darken($attr-atr-bg, 7%);
$attr-atr-small-text: lighten(#2b1456, 50%);

$attr-cwoa-bg: #0c2367;
$attr-cwoa-text: $color-white;
$attr-cwoa-highlight: #ffe86d;
$attr-cwoa-panel-text: $color-white;
$attr-cwoa-panel1-bg: #bf1d1e;
$attr-cwoa-panel2-bg: #780909;
$attr-cwoa-summary-cta: darken($attr-cwoa-highlight, 20%);
$attr-cwoa-summary-cta-text: $attr-cwoa-bg;
$attr-cwoa-summary-text-color: $attr-cwoa-text;
$attr-cwoa-summary-heading-bg: darken($attr-cwoa-bg, 14%);
$attr-cwoa-summary-text-bg: darken($attr-cwoa-bg, 7%);
$attr-cwoa-small-text: lighten(#7b2a60, 50%);

$attr-llw-bg: #fad124;
$attr-llw-text: $color-black;
$attr-llw-highlight: #e61826;
$attr-llw-panel-text: $color-white;
$attr-llw-panel1-bg: #4065ad;
$attr-llw-panel2-bg: #6e498c;
$attr-llw-summary-cta: $attr-llw-highlight;
$attr-llw-summary-cta-text: $color-white;
$attr-llw-summary-text-color: $color-white;
$attr-llw-summary-heading-bg: darken(#272A94, 7%);
$attr-llw-summary-text-bg: #272A94;
$attr-llw-small-text: #1d174c;

$attr-tp-bg: #5436AE;
$attr-tp-text: $color-white;
$attr-tp-highlight: $color-white;
$attr-tp-panel-text: $color-white;
$attr-tp-panel1-bg: #1dace3;
$attr-tp-panel2-bg: #142248;
$attr-tp-summary-cta: #3c585d;
$attr-tp-summary-cta-text: $attr-tp-text;
$attr-tp-summary-text-color: #fff;
$attr-tp-summary-heading-bg: darken(#7da4ab, 28%);
$attr-tp-summary-text-bg: darken(#7da4ab, 14%);
$attr-tp-small-text: #fff;

$attr-wc-bg: #094260;
$attr-wc-text: $color-white;
$attr-wc-highlight: $color-white;
$attr-wc-panel-text: $color-white;
$attr-wc-panel1-bg: #a11a17;
$attr-wc-panel2-bg: #741311;
$attr-wc-summary-cta: $attr-wc-highlight;
$attr-wc-summary-cta-text: $attr-wc-bg;
$attr-wc-summary-text-color: $attr-wc-text;
$attr-wc-summary-heading-bg: darken($attr-wc-bg, 14%);
$attr-wc-summary-text-bg: darken($attr-wc-bg, 7%);
$attr-wc-small-text: #ccc;

$attr-bpt-bg: #098fc3;
$attr-bpt-text: $color-white;
$attr-bpt-highlight: $color-white;
$attr-bpt-panel-text: $color-white;
$attr-bpt-panel1-bg: #63227F;
$attr-bpt-panel2-bg: #6e498c;
$attr-bpt-summary-cta: #c8008d;
$attr-bpt-summary-cta-text: $color-white;
$attr-bpt-summary-text-color: $attr-bpt-text;
$attr-bpt-summary-heading-bg: darken($attr-bpt-bg, 14%);
$attr-bpt-summary-text-bg: darken($attr-bpt-bg, 7%);
$attr-bpt-small-text: lighten(#00adf0, 50%);

$attr-bga-bg: #333;
$attr-bga-text: $color-white;
$attr-bga-highlight: $color-white;
$attr-bga-panel-text: $color-white;
$attr-bga-panel1-bg: #9d2a0b;
$attr-bga-panel2-bg: #c73007;
$attr-bga-summary-cta: #e84315;
$attr-bga-summary-cta-text: $color-white;
$attr-bga-summary-text-color: $attr-bga-text;
$attr-bga-summary-heading-bg: darken($attr-bga-bg, 14%);
$attr-bga-summary-text-bg: darken($attr-bga-bg, 7%);
$attr-bga-small-text: lighten(#9d2a0b, 50%);

$attr-le-bg: #f0eff0;
$attr-le-text: #2f2f2f;
$attr-le-highlight: #4b0c62;
$attr-le-panel-text: $color-white;
$attr-le-panel1-bg: darken(#4b0c62, 15%);
$attr-le-panel2-bg: darken(#4b0c62, 5%);
$attr-le-summary-cta: $attr-le-highlight;
$attr-le-summary-cta-text: $color-white;
$attr-le-summary-text-color: $attr-le-text;
$attr-le-summary-heading-bg: darken($attr-le-bg, 14%);
$attr-le-summary-text-bg: darken($attr-le-bg, 7%);
$attr-le-small-text: #5f5f5f;

$attr-td-bg: #000000;
$attr-td-text: $color-white;
$attr-td-highlight: #ff5651;
$attr-td-panel-text: $color-white;
$attr-td-panel1-bg: #e62b25;
$attr-td-panel2-bg: #b6211d;
$attr-td-summary-cta: $attr-td-highlight;
$attr-td-summary-cta-text: $attr-td-text;
$attr-td-summary-text-color: $attr-td-text;
$attr-td-summary-heading-bg: darken($attr-td-bg, 14%);
$attr-td-summary-text-bg: darken($attr-td-bg, 7%);
$attr-td-small-text: #a9a9a9;

$attr-js-bg: #003d6b;
$attr-js-text: $color-white;
$attr-js-highlight: $color-orange;
$attr-js-panel-text: $color-white;
$attr-js-panel1-bg: #4065ad;
$attr-js-panel2-bg: #6e498c;
$attr-js-summary-cta: $attr-js-highlight;
$attr-js-summary-cta-text: $attr-js-text;
$attr-js-summary-text-color: $attr-js-text;
$attr-js-summary-heading-bg: darken($attr-js-bg, 14%);
$attr-js-summary-text-bg: darken($attr-js-bg, 7%);
$attr-js-small-text: lighten(#003d6b, 50%);

$attr-ldc-bg: #fad124;
$attr-ldc-text: $color-black;
$attr-ldc-highlight: #e61826;
$attr-ldc-panel-text: $color-white;
$attr-ldc-panel1-bg: #4065ad;
$attr-ldc-panel2-bg: #6e498c;
$attr-ldc-summary-cta: $attr-ldc-highlight;
$attr-ldc-summary-cta-text: $color-white;
$attr-ldc-summary-text-color: $color-white;
$attr-ldc-summary-heading-bg: darken(#272A94, 7%);
$attr-ldc-summary-text-bg: #272A94;
$attr-ldc-small-text: #1d174c;

$attr-mt-bg: #7e0412;
$attr-mt-text: $color-white;
$attr-mt-highlight: #ffd650;
$attr-mt-panel-text: $color-white;
$attr-mt-panel1-bg: #7B0613;
$attr-mt-panel2-bg: #79122A;
$attr-mt-summary-cta: $attr-mt-highlight;
$attr-mt-summary-cta-text: $attr-mt-bg;
$attr-mt-summary-text-color: $attr-mt-text;
$attr-mt-summary-heading-bg: darken($attr-mt-bg, 14%);
$attr-mt-summary-text-bg: darken($attr-mt-bg, 7%);
$attr-mt-small-text: lighten(#7e0412, 50%);

$attr-sl-bg: #17123b;
$attr-sl-text: $color-white;
$attr-sl-highlight: $color-orange;
$attr-sl-panel-text: $color-white;
$attr-sl-panel1-bg: #4065ad;
$attr-sl-panel2-bg: #6e498c;
$attr-sl-summary-cta: $attr-sl-highlight;
$attr-sl-summary-cta-text: $attr-sl-text;
$attr-sl-summary-text-color: $attr-sl-text;
$attr-sl-summary-heading-bg: darken($attr-sl-bg, 14%);
$attr-sl-summary-text-bg: darken($attr-sl-bg, 7%);
$attr-sl-small-text: lighten(#17123b, 50%);

$attr-sa-bg: #020403;
$attr-sa-text: $color-white;
$attr-sa-highlight: #dddd0a;
$attr-sa-panel-text: $color-white;
$attr-sa-panel1-bg: #786c4d;
$attr-sa-panel2-bg: #aaaa0d;
$attr-sa-summary-cta: $attr-sa-highlight;
$attr-sa-summary-cta-text: $attr-sa-bg;
$attr-sa-summary-text-color: $attr-sa-text;
$attr-sa-summary-heading-bg: darken($attr-sa-panel1-bg, 14%);
$attr-sa-summary-text-bg: darken($attr-sa-panel1-bg, 7%);
$attr-sa-small-text: lighten(#020403, 50%);

$attr-upgrade-bg: #292a5d;
$attr-upgrade-text: $color-white;
$attr-upgrade-highlight: #292a5d;
$attr-upgrade-panel-text: $color-white;
$attr-upgrade-panel1-bg: #292a5d;
$attr-upgrade-panel2-bg: #292a5d;
$attr-atr-summary-cta: $attr-atr-highlight;
$attr-atr-summary-cta-text: $attr-atr-text;
$attr-upgrade-summary-text-color: $attr-upgrade-text;
$attr-upgrade-summary-heading-bg: darken($attr-upgrade-panel1-bg, 14%);
$attr-upgrade-summary-text-bg: darken($attr-upgrade-panel1-bg, 7%);
$attr-upgrade-small-text: lighten(#292a5d, 50%);

$attr-bptc-bg: #4a7db2;
$attr-bptc-text: $color-white;
$attr-bptc-highlight: $color-white;
$attr-bptc-panel-text: $color-white;
$attr-bptc-panel1-bg: #c7242c;
$attr-bptc-panel2-bg: #e06002;
$attr-bptc-summary-cta: #c8008d;
$attr-bptc-summary-cta-text: $color-white;
$attr-bptc-summary-text-color: $attr-bptc-text;
$attr-bptc-summary-heading-bg: darken($attr-bptc-bg, 14%);
$attr-bptc-summary-text-bg: darken($attr-bptc-bg, 7%);
$attr-bptc-small-text: lighten(#00adf0, 50%);

$attr-pr-bg: #2669bc;
$attr-pr-text: $color-white;
$attr-pr-highlight: $color-white;
$attr-pr-panel-text: $color-white;
$attr-pr-panel1-bg: #e4002b;
$attr-pr-panel2-bg: #8c0008;
$attr-pr-summary-cta: #c8008d;
$attr-pr-summary-cta-text: $color-white;
$attr-pr-summary-text-color: $attr-pr-text;
$attr-pr-summary-heading-bg: darken($attr-pr-bg, 14%);
$attr-pr-summary-text-bg: darken($attr-pr-bg, 7%);
$attr-pr-small-text: lighten(#00adf0, 50%);

// Mix-ins

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	-o-border-radius: $radius;
	border-radius: $radius;
}

@mixin transform($transform) {
	-webkit-transform: $transform;
	-moz-transform: $transform;
	-ms-transform: $transform;
	-o-transform: $transform;
	transform: $transform;
}

@mixin transform-origin($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-o-transform-origin: $origin;
	transform-origin: $origin;
}

@mixin backface-visibility($visibility) {
	-webkit-transform-origin: $visibility;
	-moz-transform-origin: $visibility;
	-ms-transform-origin: $visibility;
	-o-transform-origin: $visibility;
	transform-origin: $visibility;
}

@mixin transition($transition...) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin transition-property($property...) {
	-webkit-transition-property: $property;
	-moz-transition-property: $property;
	-ms-transition-property: $property;
	-o-transition-property: $property;
	transition-property: $property;
}

@mixin transition-duration($duration...) {
	-webkit-transition-duration: $duration;
	-moz-transition-duration: $duration;
	-ms-transition-duration: $duration;
	-o-transition-duration: $duration;
	transition-duration: $duration;
}

@mixin user-select($mode) {
	-webkit-user-select: $mode;
	-moz-user-select: $mode;
	-o-user-select: $mode;
	-ms-user-select: $mode;
	user-select: $mode;
}

@mixin opacity($level) {
	-webkit-opacity: $level;
	-moz-opacity: $level;
	-ms-opacity: $level;
	-o-opacity: $level;
	opacity: $level;
}

@mixin linear-gradient-x($startColor, $endColor) {
	background: -moz-linear-gradient(left, $startColor 0%, $endColor 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,$startColor), color-stop(100%,$endColor)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left,  $startColor 0%,$endColor 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left,  $startColor 0%,$endColor 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left,  $startColor 0%,$endColor 100%); /* IE10+ */
	background: linear-gradient(to right,  $startColor 0%,$endColor 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='ie-hex-str($startColor)', endColorstr='ie-hex-str($endColor)',GradientType=1 ); /* IE6-9 */
}

@mixin linear-gradient-y($startColor, $endColor) {
	background: -moz-linear-gradient(top, $startColor 0%, $endColor 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$startColor), color-stop(100%,$endColor)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  $startColor 0%,$endColor 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  $startColor 0%,$endColor 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  $startColor 0%,$endColor 100%); /* IE10+ */
	background: linear-gradient(to bottom,  $startColor 0%,$endColor 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='ie-hex-str($startColor)', endColorstr='ie-hex-str($endColor)',GradientType=0 ); /* IE6-9 */
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}


//mixin for attraction feature panels
@mixin attrFeatureStyles($bgColor, $textColor, $highlightColor, $panelTextColor, $panel1BG, $panel2BG) {
				
	h1, h2, h3, h4, p, ul li { color: $textColor; }
	ul li:before { color: $highlightColor; }
	
	.row.dividerTop {
		border-top: 1px solid lighten($bgColor, 25%);
	}
	
	ul.suitabilityList {
		li {
			color: $highlightColor;
			
			&:before { color: $highlightColor; }
			
			a, a:visited {
				color: $highlightColor;
			}
		}
	}
	
	.panel {
		color: $panelTextColor;
		background: $panel1BG;
		
		&.priceTypesContainer {
			background: darken($bgColor, 10%);
			border: none;
			border-radius: 0px 0px 15px 15px;
			
			.priceDecriptionTextArea {
				p {
					color: $textColor;					
				}
			}
		}
		
		h1, h2, h3, h4, p, ul li { color: $panelTextColor; }
		
		h2.whosVisitingHeading {
			color: $textColor;
		}
		p.termsLine {
			color: $textColor;			
		}
		
		.ticketCategories { background: darken($panel1BG, 10%); }			
		.selectArea { background: darken($panel1BG, 10%) }			
		
		.panelContent {
			h2 {
				.savingsMessage { color: darken($panel1BG, 10%); }
			}
		}
		
		&.panelVariation {
			background: $panel2BG;	
			
			.ticketCategories { background: darken($panel2BG, 10%); }				
			.selectArea { background: darken($panel2BG, 10%); }	
			.panelContent {
				h2 {
					.savingsMessage { color: darken($panel2BG, 10%); }
				}
			}			
		}

        &.panelHighlighted {
            background: $color-white;
            @include box-shadow (0px, 0px, 15px, lighten($panel1BG, 25%));

            .panelContent {
                h2, p {
                    color: $panel1BG;
                }

                .priceConfirmArea p {
                    a.yesButton:hover, a.noButton:hover {
                        background: $panel1BG;
                    }
                }
            }
        }

        &.panelVariation.panelHighlighted {
            background: $color-white;
            @include box-shadow (0px, 0px, 15px, lighten($panel2BG, 25%));

            .panelContent {
                h2, p {
                    color: $panel2BG;
                }

                .priceConfirmArea p {
                    a.yesButton:hover, a.noButton:hover {
                        background: $panel2BG;
                    }
                }
            }
        }
	}
	
	a, a:visited {
		color: $panelTextColor;
		
		&:hover { color: $panelTextColor; }
		
		&.nextButton {
			background: $highlightColor;
			color: $panelTextColor;
			
			&:hover {
				background: $panelTextColor;
				color: $highlightColor;
			}
		}
	}	
	
}


//mixin for attraction summary panels
@mixin attrSummaryStyles($bgColor, $ctaColor, $ctaTextColor, $summaryTextColor, $summaryHeadingBG, $summaryTextBG, $smallTextColor) {
	
	background: $bgColor;
	
	.panelContent {
		h1, h2, h3, h4, p {
			color: $summaryTextColor;
		}
		
		h3 {
			background: $summaryHeadingBG;
		}
		p {
			background: $summaryTextBG;
			border-bottom: 1px solid $summaryHeadingBG;
			margin-bottom: 0px;
			
			&.summaryLogo {
				border-bottom: none;
			}
			
			&.bottomParagraph {
				border-radius: 0px 0px 5px 5px;
				border-bottom: none;
			}
			
			&.noBackground {
				background: none;
				color: $smallTextColor;
				border-bottom: none;
				margin-bottom: 5px;
			}
		}
	}
	
	a.nextButton, a.nextButton:visited {
		background: $ctaColor !important;
		color: $ctaTextColor !important;
	}
	a.nextButton:hover {
		background: darken($ctaTextColor, 15%) !important;
		color: $ctaColor !important;
	}
}

