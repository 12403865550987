/*
********************************
*****  FULL (DESKTOP) CSS  *****
********************************
*/

.m-only {
    display: none !important;
}

.st-only {
    display: none !important;
}

.lt-only {
    display: none !important;
}

.f-only {
    display: inherit !important;
}

.inline.f-only {
    display: inline !important;
}

.lt-hide {
    display: inherit !important;
}

.inline.lt-hide {
    display: inline !important;
}

.f-hide {
    display: none !important;
}

.lt-show {
    display: none;
}

.f-show {
    display: inherit !important;
}

.inline.f-show {
    display: inline !important;
}

.m-padded {
    padding: 0px;
}

.st-padded {
    padding: 0px;
}

.lt-padded {
    padding: 0px;
}

.f-padded {
    padding: 15px 15px 5px 15px;
}

body {
    &.modal {
        height: auto;
    }
}

.bear {
	#siteWrapper{
		padding-bottom: 180px;
	}
}

#siteWrapper {
    padding-bottom: 280px;
    position: relative;
    //	background: -moz-linear-gradient(left,  rgba(22,17,57,1) 0%, rgba(22,17,57,0) 25%, rgba(22,17,57,0) 85%, rgba(22,17,57,1) 100%); /* FF3.6+ */
    //	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(22,17,57,1)), color-stop(25%,rgba(22,17,57,0)), color-stop(85%,rgba(22,17,57,0)), color-stop(100%,rgba(22,17,57,1))); /* Chrome,Safari4+ */
    //	background: -webkit-linear-gradient(left,  rgba(22,17,57,1) 0%,rgba(22,17,57,0) 25%,rgba(22,17,57,0) 85%,rgba(22,17,57,1) 100%); /* Chrome10+,Safari5.1+ */
    //	background: -o-linear-gradient(left,  rgba(22,17,57,1) 0%,rgba(22,17,57,0) 25%,rgba(22,17,57,0) 85%,rgba(22,17,57,1) 100%); /* Opera 11.10+ */
    //	background: -ms-linear-gradient(left,  rgba(22,17,57,1) 0%,rgba(22,17,57,0) 25%,rgba(22,17,57,0) 85%,rgba(22,17,57,1) 100%); /* IE10+ */
    //	background: linear-gradient(to right,  rgba(22,17,57,1) 0%,rgba(22,17,57,0) 25%,rgba(22,17,57,0) 85%,rgba(22,17,57,1) 100%); /* W3C */
    //	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#161139', endColorstr='#161139',GradientType=1 ); /* IE6-9 */
    min-width: 980px;

    .page-bear &{
        padding-bottom: 200px;
    }

    #mobileNavigationMask {
        display: none !important;
    }
}

#pageOverlayLeft, #pageOverlayRight {
    @include linear-gradient-x(rgba(0,0,0,0.3), rgba(0,0,0,0));
    height: 100%;
    width: 150px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
}

#pageOverlayRight {
    @include linear-gradient-x(rgba(0,0,0,0), rgba(0,0,0,0.3));
    left: auto;
    right: 0px;
}

body.homepage, body.prizePage {
    #pageOverlayLeft, #pageOverlayRight {
        display: none;
    }
}

#contentWrapper {
    margin-top: 0px;
    position: relative;
    padding: 0px 0px 60px 0px;
    overflow: hidden;
    min-height: 600px;

    .page-bear &{
        padding: 0px 0px 0px 0px;
    }

    .modal body & {
        padding: 0px;
        background: none;
    }

    body.homepage &, body.prizePage &, body.bookingFlowPage & {
        padding: 0px 0px 0px 0px;
    }

    body.subpage & {
        padding-top: 80px;
    }
}


blockquote.review {
    font-size: 18px;
    line-height: 22px;

    span.attribution {
        display: block;
        font-size: 16px;
        line-height: 20px;
    }
}

.largerText {
    font-size: 18px;
    line-height: 23px;
}

.anchorTag {
    display: block;

    &:before {
        content: "";
        display: block;
        height: 130px; /* fixed header height*/
        margin-top: -130px; /* negative fixed header height */
    }
}

a.nextButton.softFullWidth, a.backButton.softFullWidth {
    display: block;
}

nav.breadcrumb {
    margin-top: -5px;
}




/*
Wrapper elements
------------------
*/
a.headerLargeButton {
    box-shadow: -1px -1px 1px rgba(0,0,0,0.66) inset, 1px 1px 1px rgba(255,255,255,1) inset;
    display: block;
    padding: 0px;
    position: absolute;
    top: -7px;
    right: 25px;
    z-index: 200;
    width: 190px;
    height: 92px;
    background-color: $color-yellow;
    @include border-radius(0px 0px 8px 8px);
    color: $color-purple;
    cursor: pointer;
    font-family: $font-heading;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    position: fixed;
    transition: 500ms ease all;


    &.scrolled {
        height: 65px;

        span {
            padding-top: 10px;
        }
    }

    span {
        display: inline-block;
        vertical-align: top;
        height: 35px;
        text-transform: uppercase;
        padding-top: 30px;
        transition: padding-top 500ms ease;

        i {
            display: block;
            font-size: 1.2em;
            line-height: 1.25em;
        }

        strong {
            display: inline-block;
            vertical-align: top;
            padding: 0px 5px;
            height: 35px;
            font-family: $font-heading;
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
        }
    }

    .no-touch &:hover {
        background-color: $color-white;
        color: $color-purple;
        height: 99px;

        span {
            padding-top: 37px;
        }
    }
}


/*
Header layout
------------------
*/
#headerWrapper {
    position: relative;
    top: 0px;
    left: 0px;
    text-align: center;
    min-width: 980px;
    background: none;
}

body.subpage #headerWrapper {
    height: 120px;
}

header {
    position: relative;
    padding: 0px 0px 0px 0px !important;
    margin: 0px auto 0px auto;
    width: 100%;
    text-align: left;
}

#headerContent {
    width: 100%;
    background-color: transparent;
}

a#headerLogo {
    top: 0px;
    left: 0px;

    img {
        margin: 0;
        width: 100%;

        &.logoImage {
            top: 0px;
            left: 0px;
            right: auto;
            max-width: 235px;
        }
    }
}

.logoFadeImage {
    display: block;
    top: 0px;
    width: 650px;
}

.iconButton {
    width: 100px;
    text-align: left;

    .textArea {
        display: inline-block !important;
        position: absolute;
        margin: 0 !important;
        top: 0px !important;
        left: 45px !important;
        width: 55px !important;
        text-align: left !important;
        font-size: 12px !important;
        line-height: 35px !important;
    }
}


#headerSocialLinks, #footerSocialLink {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 0px;
    width: 81px;
}
/*
General layout - hardcoded sizes
--------------------------------
*/
.sectionWrapper {
	margin-left: 0px;
	margin-right: 0px;
	text-align: center;
	padding: 45px 0px 45px 0px;

	&.featurePanelImgBg {
		padding-top: 475px;

		@media(min-width:1600px) {
			padding-top: 600px;
		}

		~ .overlayTitle {
			position: absolute;
			z-index: 3;
			top: 350px;
			width: 80%;
			left: 0;
			right: 0;
			margin: auto;

			@media(min-width:1600px) {
				top: 450px;
			}
		}
	}

	.rightSideImage {
		position: absolute;
		left: 50%;
		right: 0;
		top: 0;
		bottom: 0;
		background: url('/Content/images/main-images/map-side-image.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;

		.blueFade {
			height: 100%;
			width: 50%;
			background: -moz-linear-gradient(left, rgba(30,87,153,1) 0%, rgba(0,89,145,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(30,87,153,1) 0%,rgba(0,89,145,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(30,87,153,1) 0%,rgba(0,89,145,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#00005991',GradientType=1 ); /* IE6-9 */
		}
	}

	.leftSideImage {
		position: absolute;
		left: 50%;
		right: 0%;
		top: 0;
		bottom: 0;
		background: url('/Content/images/main-images/ldc-birm-side-image.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;

		.ldcFade {
			height: 100%;
			width: 50%;
			background: -webkit-linear-gradient(left, #efc40c 0%, rgba(0,89,145,0) 100%);
			background: linear-gradient(to right, #f7cc18 0%, rgba(0,89,145,0) 100%);
		}
	}

	&.topSectionExtraSpace {
		margin-top: 150px;
	}

	&.reduceSpaceAbove {
		margin-top: 0px;
	}

	&.noFeatureImage {
		margin-top: 285px;

		&.noTopHeader {
			margin-top: 50px;
		}
	}

	&.shortContent {
		height: 550px;
	}

	section {
		margin: 0px auto 0px auto;
		max-width: 1500px;
		width: 90%;

		.modal & {
			width: 90%;
		}
	}

	&.featurePanel {
		&.blueGradientBG {
			padding: 55px 0px 55px 0px;
		}
	}
}


// paragraph styles
p {
    font-size: 1.25em;
    max-width: 950px;
    margin: 0px 0px 17px 0px;

    .alignCenter &, .alignCentre & {
        margin: 0px auto 17px auto;
    }

    &.noMaxWidth {
        max-width: none;
    }
}

h1 {
    font-size: 50px;
    line-height: 56px;
}

h2 {
    font-size: 42px;
    line-height: 50px;
}

h3 {
    font-size: 36px;
    line-height: 40px;
}

h4 {
    font-size: 27px;
    line-height: 32px;
}


ul.perksList {
    margin: 35px 0px 35px 0px;

    li {
        font-size: 100%;
        line-height: 1.3em;
    }

    &.perksBoxes {
        margin: 0px 0px 15px 0px;

        li {
            float: left;
            font-size: 0.7em;
            height: 111px;
            margin: 0px 0px 0px 3%;
            width: 26%;

            i {
                line-height: 1.8em;
            }
        }
    }
}

.sectionWrapper {

    &.featurePanel {
        margin: 0 -2.5%;
        width: 105%;
    }


    body.bookingFlowPage & {
        margin: 0;
        width: 100%;
    }

    &.featurePanel {
        margin: 0;
        width: 100%;

        #contentWrapper &.contentRight {
            .featurePanelMobileImage {
                margin: 2.5% 2.5% 2.5% 24%;
            }

            section {
                margin: 0;
            }
        }

        .featurePanelMobileImage {
            width: 38%;
        }

        &.blueGradientBG {
            img.breakOutImage {
                margin: -60px 0px 0px 0px;
                max-width: 780px;
            }
        }
    }
}



/*
Main features
---------------------------
*/

#mainFeatureContainer {
    margin: 0px;
    padding-top: 0px;
    width: 100%;
    overflow: visible;

    #featureContainer {
        width: 100%;
        min-height: 100%;
        img {
            margin-top: 0px;
            width: 100%;
        }

        .bg-video {
            margin-top: 0;
            height: 100vh;
            iframe {
                width: calc(100% * 1.77778);
                height: 100vw;
                @media (min-aspect-ratio: 16/9) {
                    width: 100%;
                    height: calc(100vw * .5625);
                }
            }
        }

        #featureOverlay {
            height: 350px;
        }

        #featureOverlayTop {
            height: 150px;
            top: -50px;
        }
    }

    h1.mainFeatureHeaderCTA {
        a {
            font-size: 1.3em;
            line-height: 1em;

            .largeWord {
                display: block;
                font-size: 1.5em;
                line-height: 1em;
            }
        }
    }
}


#topBGImageContainer {
    top: 0px;
    width: 100%;

    img {
        width: 100%;
    }

    #topBGImageOverlay {
        height: 200px;
    }
}


/*
Top Section elements
------------------
*/

#menuLauncher {
    display: none;
}



/* Menu layout */

#nav {
    text-align: left;
    position: absolute;
    top: 14px;
    right: 230px;
    width: 62%;
    z-index: 1000;
}

#menuArea {
    display: block;
    margin: 0px;
    overflow: hidden;
    width: 100%;
    height: auto;
    z-index: 10;

    dl.menuAreas {
        float: right;
        margin: 0px;
        font-size: 24px;
        line-height: 32px;
        position: relative;
        border: none !important;

        dt {
            display: inline;
            float: left;
            margin: 0 10px 0 0;
            padding: 0;
            text-align: left;

            a, a.withSubmenu {
                background: none;
                border: none;
                @include border-radius(8px);
                display: block;
                position: relative;
                margin: 0;
                padding: 0;
                font-family: $font-heading;
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                text-transform: uppercase;
                color: $color-yellow;
                @include font-smoothing(on);

                .menuTitleWrapper {
                    cursor: pointer !important;
                    display: block;

                    &:hover {
                        cursor: pointer !important;
                    }

                    .menuTitle {
                        cursor: pointer !important;
                        display: block;
                        font-size: 18px;
                        line-height: 24px;
                        padding: 10px;
                        position: relative;
                        text-align: center;

                        &:hover {
                            cursor: pointer !important;
                        }

                        i {
                            display: block;
                            font-size: 1.2em;
                            line-height: 1.25em;
                        }
                    }
                }

                &:hover {
                    background: transparentize(darken($color-purple, 20%), 0.25);
                    color: $color-white;
                    cursor: pointer !important;
                }

                &.withSubmenu .expander {
                    display: none;
                }
            }
        }

        dt.menuOn a, dt.menuOn a:visited {
            background: none;
            border: none;
            color: lighten($color-yellow, 35%);
            text-shadow: 0px 0px 7px $color-orange, 0px 0px 5px $color-orange, 0px 0px 3px $color-orange, 0px 0px 1px $color-orange;
        }

        dd.submenu {
            display: none !important;
            border: none !important;
        }
    }
}

.imageContainer {
    margin: 0px 0px 0px 0px;

    .pictureShadow {
        max-width: 456px;
    }
}


// Split layout

.split-layout {
    flex-direction: row;
    align-items: stretch;
    min-height: 37.5vw;
    .split-layout--section {
        min-height: 100%;
        .split-layout--section--content {
            padding: 15px 30px 45px;
        }
    }
}


/*
Attraction listings
------------------
*/
ul.attractionListing, ul.attractionListingSinglePack {
    width: 104%;

    li {
        margin-bottom: 2%;
        width: 17.6%;

        &:nth-child(2n) {
            margin-right: 2%;
        }

        &:nth-child(3n) {
            margin-right: 2%;
        }
    }
}

.panel.bear h2 ~ p {
	max-width: 50%;
	margin: auto;
}

.panel.priceTypesContainer .panel {
    margin: 0px auto;
    width: 95%;
}

.panel.activeAttractionBox {
    .panel.priceTypesContainer {

        .panelContent {
            padding-bottom: 30px;
            position: relative;

            h2 {
                font-size: 46px;
                margin-top: 25px;
            }

            .peopleIcon {
                position: absolute;
                bottom: -15px;
                left: -15px;
            }

            p.smallText.termsLine {
                margin-bottom: 5px !important;
                margin-top: 25px !important;
            }
        }

        &.panelHighlighted {
            .peopleIcon {
                display: none;
            }
        }
    }

    .gugfLogoSmall {
        margin-top: -50px;
    }
}


/*
Booking flow
------------------
*/
.flowSection {

    #flowSection3 {
        p {
            max-width: none !important;
        }
    }


    .panel {
        &.summaryPanel {
            .panelContent {
                padding: 25px;

                h3 {
                    padding: 12px 0px;
                }

                p {
                    padding: 12px 0px;
                }
            }
        }
    }
}



/*
Footer layout
------------------
*/

#footerWrapper {
    text-align: center;
    min-width: 980px;
    overflow: hidden;

    #footerDecoration {
        position: relative;
        margin: 0px 0px 0px 0px;
        text-align: left;
        width: 100%;

        footer {
            position: relative;
            margin: 0px auto 0px auto;
            padding: 30px 0px 10px 0px;
            width: 980px;

            p {
                max-width: none;

                &.smallText {
                    margin: 25px 0px 40px 0px;
                }
            }
        }

        .merlinLogoArea {
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 320px;
        }
    }
}

/*
Step-by-step guide styles 
---------------------------
*/
.dottedLines {
    position: absolute;
    top: 0;
    left: 0;

    img {
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}

.circlePanel {
    &.circlePanelOne {
        margin: 0 0 0 -340px;
    }

    &.circlePanelTwo {
        margin: 0 -300px 0 0;

        .orbImages .kravePackaging {
            position: absolute;
            top: 10px;
            left: 250px;
        }
    }

    &.circlePanelThree {
        margin: 0 0 0 -160px;
    }
}

/*
asda promo styles
---------------------------
*/
body.homepage.asda {
    #siteWrapper {
        padding-bottom: 215px;
    }

    .sectionWrapper {
        &.featurePanel {
            &.purpleSpaceBG {
                padding-top: 5px;
            }
        }
    }

    h1 {
        span.big {
            font-size: 70px;
            line-height: 86px;
            text-shadow: -3px -3px 0 #602e67, 1px -3px 0 #602e67, -3px 3px 0 #602e67, 3px 3px 0 #602e67;

            span.biggest {
                font-size: 90px;
            }
        }
    }
}
/* Short Breaks Styles */
.logosContainer {
    .shortBreakLogo {
        display: inline-block;
        width: 85px;
        margin: 5px;
        padding: 2px;
        border-radius: 5px;
        background: $attr-wc-bg;
    }
}

#win-flash {
    display: inline-block;
    position: absolute;
    z-index: 10;
    width: 150px;
    right: 38px;
}

#now-open-flash {
    position: absolute;
    z-index: 10;
    width: 200px;
    left: 46%;
    top: 33%;
}

@media screen and (min-width: 980px) {
    .sectionWrapper .leftSideImageMap .mapFade {
        height: 100%;
        width: 15%;
        background: -webkit-linear-gradient(left, #29377e 0%, rgba(0,89,145,0) 100%);
        background: linear-gradient(to right, #29377e 0%, rgba(0,89,145,0) 100%);
    }
}

@media screen and (min-width: 980px) {
    .sectionWrapper .leftSideImageMap {
        position: absolute;
        left: 50%;
        right: 0%;
        top: 0;
        bottom: 0;
        background: url(/Content/images/homepage/win-2021.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
    }
}

#contentWrapper {
	.bear {
		.singleLineFormWrap {

			.input {
				input {
					width: 80%;
				}
			}

			button {
				cursor: pointer;
				font-weight: normal;
			}

			input, button {
				padding: 15px;
				font-size: 24px;
				font-weight: bold;
			}

			.copyBtn {
				margin-top: 30px;
				margin-bottom:0px;
			}
		}
	}
}