/*
******************************
*****  SMALL TABLET CSS  *****
******************************
*/
	
	.m-only {
    	display: none !important;
	}
	.st-only {
    	display: inherit !important;
	}
	.lt-only {
    	display: none !important;
	}
	.f-only {
    	display: none !important;
	}
	
	.m-hide {
    	display: inherit !important;
	}
	.inline.m-hide {
    	display: inline !important;
	}
	
	.st-hide {
    	display: none !important;
	}
	
	.m-show {
    	display: none;
	}
	.st-show {
    	display: inherit !important;
	}
	.inline.st-show {
    	display: inline !important;
	}

	.m-padded {
		padding: 0px;
	}
	.st-padded {
		padding: 15px 15px 5px 15px;
	}

	.googleMap {
		width: 80%;
		height: 250px;
	}

/*
Wrapper elements
------------------
*/
	#menuLauncher {
		top: 80px;
		left: 15px;
	}
	
	.headerButton {		
		
		&.win {
			top: 80px;
			left: 68px;
		}
		&.book {
			top: 80px;
			right: 68px;
		}
		&.help {
			top: 80px;
			right: 15px;
		}
		
	}

/*
Header
------------------
*/
		
	.iconButton {
		width: 100px;
		text-align: left;
		.textArea {
			display: inline-block !important;
			position: absolute;
			margin: 0 !important;
			top: 0px !important;
			left: 45px !important;
			width: 55px !important;
			text-align: left !important;
			font-size: 12px !important;
			line-height: 35px !important;
		}
	}
	
	/* homepage header overrides */

	.homepage #headerWrapper {
		//height: 60px !important;
	}
	.homepage #headerContent {
		//height: 56px !important;
	}
	
/*
General layout
------------------
*/

	@media(min-width:480px) and (max-width:980px){
		.page-bear #contentWrapper {
			padding: 0px 15px 0px 15px;
		}
	}

	#contentWrapper {
		padding: 195px 20px 40px 20px;
		
		.bookingFlowPage & {
			padding: 5px 20px 40px 20px;			
		}
    }
	
	.sectionWrapper {
		margin: 0px -20px;
		
		&.noFeatureImage {
			padding-top: 0px;
		}
	}
	
	#miscTags {
		display: none;
	}
	
	
	// paragraph styles 
	p {
		margin: 0px 0px 12px 0px;
		font-size: 1em;
		line-height: 1.50em;
	}
	ul, ol {
		font-size: 1.05em;
		line-height: 1.25em;
		margin-bottom: 25px;
	}
		
	h1 {
		font-size: 30px;
		line-height: 38px;
	}
	
	h2 {
		font-size: 25px;
		line-height: 34px;
	}
	
	h3 {
		font-size: 22px;
		line-height: 30px;
	}
	
	h4 {
		font-size: 20px;
		line-height: 26px;
	}
	
	// list styles
	ul {
		&.perksList {				
			&.perksBoxes {
				li {
					font-size: 0.75em;
					line-height: 1.2em;
					text-align: center;
					text-transform: uppercase;
					
					i {
						display: block;
						font-size: 2em;
						line-height: 1.5em;
						margin: 0px auto;
					}
				}
			}			
		}
	}

/*
Forms
------------------
*/
	
	.genericForm fieldset div select {
		max-width: 100%;
	}
	
/*
main features
---------------------------
*/
	
	
	.imageContainer {
		margin: 0px 0px 0px 0px;
		.pictureShadow {
			max-width: 457px;
		}
	}
	
	


/*
Attraction listings
------------------
*/
ul.attractionListing, ul.attractionListingSinglePack {
	li {
		float: left;
		margin: 0 2% 15px 0;
		max-width: 270px;
		width: 31.5%;

		&:nth-child(3n) {
			margin-right: 0;
		}


		.attractionLink {
			text-align: center;

			span {
				display: block;

				&.attractionContent {
					padding: 10px;
				}

				.attractionLogo {
					float: none;
					margin: 10px auto 10px auto;
					width: 96%;
				}

				.attractionName {
					display: none;
					font-size: 14px;
					line-height: 19px;
				}
			}
		}
	}

	&.smallItems {
		li {
			width: 13%;


			a {
				span {
					.attractionLogo {
						width: 100%;
					}

					.attractionName {
						display: none;
					}
				}
			}
		}
	}
}


/*
Booking flow
------------------
*/




/*
Panel layout
------------------
*/

	.panel {		
		
		&.attractionPanel {
			.panelContent {
				.attractionLogo {
					width: 100%;
					a {
						img {
							margin-top: 0px;
							max-width: 75%;
						}
					}
				}
				
				.attractionImage {
					margin-top: 80px;
					
					&.rotate1 {
						@include transform(rotate(2deg));
					}
					&.rotate2 {
						@include transform(rotate(-2deg));
					}
				}
			}
		}
		
		&.attractionPanel.atr {
			.panelContent {
				.attractionImage {
					margin-top: 120px;
				}
				.attractionLogo a img {
					margin: 0 auto;
				}
			}
		}
		&.attractionPanel.cwoa {
			.panelContent {
				.attractionImage {
					margin-top: 115px;
				}
				.attractionLogo a img {
					margin: 0 auto;
				}
			}
		}
		
	}



/*
Footer
-----------------------
*/

	#footerWrapper {
		width: 100%;
		color: $color-white;
		a {
			color: $color-white;
		}
		#footerDecoration {
			width: 100%;
			
			footer {
				a {
					display: inline;
				}
				.divider {
					display: inline;
				}
			}			
		}
	}

/*
Step-by-step guide styles 
---------------------------
*/

.circlePanel {
    background-size: 740px 800px;
    height: 800px;
}
.circlePanelContent {
    padding: 130px 0px 0px 0px;
    margin: 0px auto 0px auto;
    width: 450px;
}