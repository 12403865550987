/*
******************************
*****  MOBILE FIRST CSS  *****
******************************
*/
html {
    height: 100%;
}

body {
    width: 100%;
    position: relative;
    margin: 0px;
    padding: 0px;
    background: $body-bg-color;
    color: $color-red;
    -webkit-text-size-adjust: 100%;
    font-family: $font-body;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;

    .modal & {
        background-color: $color-white;
        background-image: none !important;
        height: auto !important;
    }

    .videoModal & {
        background-color: $color-black;
        color: $color-white;
        background-image: none !important;
        height: 100%;
        overflow: hidden;
    }

    strong {
        font-family: $font-body;
        font-weight: 400;
    }

    .jsNav & {
        background-color: #333;
        color: $color-white;
        min-height: 100% !important;
    }
}

.m-only {
    display: inherit !important;
}

.st-only {
    display: none !important;
}

.lt-only {
    display: none !important;
}

.f-only {
    display: none !important;
}

.m-hide {
    display: none !important;
}

.all-hide {
    display: none !important;
}

.m-show {
    display: inherit;
}

.inline.m-show {
    display: inline;
}

.st-show {
    display: none;
}

.lt-show {
    display: none;
}

.f-show {
    display: none;
}

.m-padded {
    padding: 15px 15px 5px 15px;
}

.all-padded {
    padding: 15px 15px 15px 15px !important;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.pad-0 {
    padding: 0 !important;
}

.bg--blue{
    background: $color-rip-panel-blue;
}

/*
Wrapper elements
------------------
*/

#viewportWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
}

#siteWrapper {
    width: 100%;
    left: 0;
    background: $color-dark-blue;
    background-size: 100%;
    color: $color-white;

    #mobileNavigationMask {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
        //background: red;
    }

    body.modalPage & {
        background: $color-dark-purple;
    }
}

.menuActive {
    #viewportWrapper {
        overflow: hidden;
    }
}

#menuLauncher {
    display: block;
    padding: 0px;
    position: absolute;
    z-index: 110;
    top: 45px;
    left: 15px;
    width: 43px;
    height: 43px;
    background-color: $color-purple;
    @include border-radius(4px);
    cursor: pointer;

    .menuLauncherBar {
        display: block;
        position: absolute;
        left: 10px;
        width: 22px;
        height: 3px;
        background-color: $color-yellow;
    }

    #barOne {
        top: 8px;
    }

    #barTwo {
        top: 15px;
    }

    #barThree {
        top: 22px;
    }

    strong {
        position: absolute;
        color: #ffea00;
        font-size: 9px;
        line-height: 11px;
        top: 28px;
        left: 10px;
    }

    .no-touch &:hover {
        background-color: $color-white;

        .menuLauncherBar {
            background-color: $color-purple;
        }

        strong {
            color: $color-purple;
        }
    }

    &.on {
        background-color: $color-white;

        .menuLauncherBar {
            background-color: $color-purple;
        }

        strong {
            color: $color-purple;
        }
    }
}

.headerButton, .headerButton:visited {
    display: block;
    padding: 0px;
    position: absolute;
    z-index: 200;
    width: 43px;
    height: 43px;
    background-color: $color-purple;
    @include border-radius(4px);
    color: $color-yellow;
    cursor: pointer;
    font-size: 23px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;

    .no-touch &:hover {
        background-color: $color-white;
        color: $color-purple;
    }

    span {
        display: inline-block;
        vertical-align: top;
        height: 35px;
        text-transform: uppercase;
        padding-top: 6px;

        i {
            line-height: 21px;
        }

        strong {
            display: inline-block;
            vertical-align: top;
            padding: 0px 5px;
            height: 35px;
            font-size: 9px;
            line-height: 14px;
        }
    }


    &.win {
        top: 98px;
        left: 15px;
    }

    &.book {
        top: 45px;
        right: 15px;
    }

    &.help {
        top: 98px;
        right: 15px;

        .bookingFlowPage & {
            height: auto;
            top: 7px;
            right: 7px;
            width: auto;

            i {
                display: inline;
                font-size: 14px;
                line-height: 21px;
            }

            span {
                height: 25px;
                padding: 0px 0px 0px 5px;

                strong {
                    display: inline;
                    font-size: 12px;
                    line-height: 26px;
                }
            }
        }
    }
}

.whiteText{
    color:#fff!important;
    a{
        color:#fff!important;
        text-decoration:underline;
    }
}

.headerLargeButton {
    display: none;
}

/* 3D transform support */

.mobileNav.csstransforms3d body {
    #nav {
        left: 0;
        @include transform(translate3d(-100%, 0, 0));
        @include backface-visibility(hidden);
    }

    #siteWrapper {
        left: 0 !important;
        @include transform(translate3d(0, 0, 0));
        @include transition(300ms ease);
        @include backface-visibility(hidden);
    }
}

.mobileNav.csstransforms3d.mobileMenuActive body {
    #siteWrapper {
        @include transform(translate3d(81%, 0, 0));
    }

    #nav #menuArea {
        @include transform(translate3d(0, 0, 0));
    }
}




/*
Misc elements
------------------
*/
i.fa {
    font-weight: normal !important;
}

.normalText {
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: $font-body;
}

.clearBoth {
    display: block;
    clear: both;
    height: 0px;
    overflow: hidden;
    visibility: hidden;
}

.hidden {
    display: none !important;
}

.topMargin {
    margin-top: 20px !important;
}

.noTopMargin {
    margin-top: 0 !important;
}

.noBottomMargin {
    margin-bottom: 0 !important;
}

.noTopPadding {
    padding-top: 0 !important;
}

.noBottomPadding {
    padding-bottom: 0 !important;
}

.noMargins {
    display: block !important;
    margin: 0 !important;
}

.extraBottomMargin25 {
    margin-bottom: 25px !important;
}

.row.extraBottomMargin {
    margin-bottom: 20px !important;
}

.extraTopMargin {
    margin-top: 25px !important;
}

.dropShadow {
    box-shadow: 2px 2px 5px rgba(0,0,0,0.33);
}

.rotateLeft {
    @include transform(rotate(-3deg));
}

.rotateRight {
    @include transform(rotate(3deg));
}

hr {
    display: none;
}

hr.dividerRule {
    display: block;
    margin: 30px 0px 30px 0px;
    width: 100%;
    height: 0px;
    border: none;
    border-top: 1px solid $color-grey;
}

p {
    margin-bottom: 15px;
}

.smallText {
    font-size: 14px;
    line-height: 19px;

    &.fadedOut {
        opacity: 0.6;
    }
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    box-sizing: border-box;
    max-width: 100%;
    border: none;
    vertical-align: bottom;

    &.picBorder {
        border: 2px solid $color-white;
        box-sizing: border-box;
    }

    a &.picBorder {
        border: 2px solid $color-white;
        ;
        text-decoration: none;
    }

    a:hover &.picBorder {
        border: 2px solid $color-dark-red;
        text-decoration: none;
    }
}

.pictureShadow {
    margin: 0px;
    padding: 0px;
    vertical-align: top !important;
}

.floatLeft {
    float: left;
    margin-right: 10px;
}

.floatRight {
    float: right;
    margin-left: 10px;
}

.picCaption {
    margin-top: 4px;
    font-style: italic;
}


// list styles
ul {
    display: block;
    font-size: 1.1em;
    line-height: 1.25em;
    margin: 0px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
    list-style-type: none;
    list-style-position: outside;
    position: relative;
    overflow: hidden;

    &.largeText {
        font-size: 1.35em;
    }

    li {
        position: relative;
        margin: 0px 0px 12px 0px;
        padding: 0px 0px 0px 30px;

        &:before {
            font-family: $font-icon;
            font-weight: normal;
            content: '\f006';
            color: $list-style-color;
            font-size: 70%;
            margin-left: -20px;
            margin-right: 8px;
        }

        .mobileAlignCenter & {
            padding: 0px;

            &:after {
                font-family: $font-icon;
                font-weight: normal;
                content: '\f006';
                color: $list-style-color;
                font-size: 70%;
                margin-right: -20px;
                margin-left: 8px;
            }
        }
    }



    &.noBullets li:before {
        content: '';
        display: none;
    }

    &.tickList {
        li {
            padding: 0px 0px 0px 13px;
        }

        li:before {
            font-family: $font-icon;
            font-weight: normal;
            content: '\f00c';
            color: $list-style-color;
            font-size: 150%;
            line-height: 1.1em;
            margin-left: -25px;
            margin-right: 8px;
        }
    }

    &.perksList {
        li {
            font-size: 0.8em;
            line-height: 1.2em;
            margin: 0px auto 20px auto;
            padding: 0px;
            text-transform: uppercase;
            width: 66%;

            i {
                display: block;
                font-size: 2.1em;
                line-height: 1.3em;
            }
        }

        &.perksBoxes {
            li {
                @include border-radius(4px);
                padding: 8px;
            }
        }
    }


    &.buttonList {
        li {
            display: block;
            margin: 0px 0px 2px 0px;
            padding: 0px;

            &:before {
                content: '';
                display: none;
            }

            a, a:visited {
                background: $link-color;
                display: block;
                padding: 5px;
                text-decoration: none;

                .underline {
                    text-decoration: underline;
                }
            }
        }
    }

    &.boxList {
        li {
            background: lighten($color-lighter-grey, 5%);
            display: block;
            float: left;
            margin: 0px;
            padding: 0px;

            &:before {
                display: none;
                content: "";
                margin-left: 0px;
            }
        }

        &.threeItems {
            width: 103%;

            li {
                margin-right: 2%;
                width: 31%;

                &.lastItem {
                    margin-right: 0;
                }
            }
        }

        &.twoItems {
            width: 104%;

            li {
                margin-right: 2%;
                width: 47%;

                &.lastItem {
                    margin-right: 0;
                }
            }
        }
    }
}


ol {
    font-size: 1.1em;
    line-height: 1.55em;
    margin: 0px 0px 12px 0px;
    padding: 0px 0px 0px 22px;
    counter-reset: li;

    li {
        position: relative;
        padding: 0px 0px 12px 0px !important;
    }

    .panel & {
        li {
            padding: 0px 0px 0px 20px;
        }

        li:before {
            left: 0px;
        }
    }
}



dl {
    margin: 0px 0px 10px 0px;
    padding: 0px;
    counter-reset: li;
    list-style: none;

    dt {
        margin: 10px 0px 10px 0px;
        padding: 0px;
        font-weight: normal;
    }

    dd {
        padding: 0px 0px 5px 20px;
    }
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;

    img {
        margin-right: auto !important;
        margin-left: auto !important;
    }
}

.mobileAlignCenter {
    text-align: center;
}

.column.alignCenter {
    float: none;
    margin-right: auto !important;
    margin-left: auto !important;
}

.alignRight {
    text-align: right;
}

.upperCase {
}


ul.optionButtons {
    margin: 0px 0px 5px 0px;
    padding: 0px 0px 0px 0px;

    li {
        margin: 0px 0px 10px 0px;
        padding: 0px 0px 0px 0px;

        a {
            margin: 0px 0px 0px 0px !important;
        }
    }
}

a.nextButton, a.nextButton:visited {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    background-color: $color-pink;
    padding: 6px 36px 6px 18px;
    margin: 0px 0px 10px 0px;
    font-size: .877em;
    line-height: 1.22em;
    color: $color-white;
    text-decoration: none;
    text-transform: uppercase;
    @include border-radius(12px);
    cursor: pointer;

    strong {
        font-family: $font-body;
        font-weight: 400;
    }

    strong:before,
    span:before {
        display: block;
        position: absolute;
        top: 5px;
        right: 12px;
        font-family: $font-icon;
        font-weight: normal !important;
        @include font-smoothing(on);
        font-size: 24px;
        content: "\f105"; /* fa-angle-right */
    }

    &:focus, &:hover {
        background-color: $color-white;
        color: $color-pink;
        text-decoration: none !important;
    }

    &.smallButton {
        border: none;
        background-color: $color-purple;
        box-shadow: none;
        padding: 4px 26px 6px 8px;
        font-size: 16px;
        line-height: 18px;
    }


    &.large {
        @include border-radius(10px);
        font-size: 30px;
        padding: 15px 35px 17px 15px;

        strong:before, span:before {
            top: 15px;
            right: 10px;
            font-size: 40px;
        }
    }

    &.redButton {
        background: $color-red !important;
    }
}

a.backButton {
    @extend a.nextButton;
    padding: 4px 8px 5px 26px;

    strong:before,
    span:before {
        right: auto;
        left: 5px;
        content: "\f104"; /* fa-angle-left */
    }

    &.smallButton {
        padding: 4px 8px 5px 26px;
    }
}

a.refreshButton {
    @extend a.nextButton;
    padding: 4px 30px 6px 8px;

    strong:before,
    span:before {
        content: "\f021"; /* fa-angle-left */
        font-size: 20px;
    }
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.youTube {
        padding-bottom: 56.25%;
    }

    &.vimeo {
        padding-bottom: 56.25%;
    }
}

.videoFrame {
    background-color: $color-white;
    margin: 0;
    padding: 2px;

    .videoWrapper {
        margin: 0;
    }
}

.feedbackIcon {
    float: right;
    margin: 0 0 10px 10px;
    max-width: 64px;
}


.contentScrollCTA {
    a {
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            text-decoration: none !important;
        }
    }

    i {
        display: block;
    }
}


.scrollCTA {
    position: absolute;
    bottom: 0px;
    height: 70px;
    left: 50%;
    margin-left: -50px;
    z-index: 100;
    opacity: 0;

    a.downArrow {
        background: lighten($color-purple, 35%);
        @include border-radius(50px 50px 0px 0px);
        color: $color-purple;
        display: block;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        padding: 10px 0px;
        width: 100px;
        z-index: 200;

        .no-touch &:hover {
            background: $color-white;
            color: $color-purple;
            text-decoration: none;
        }

        i {
            display: block;
            font-size: 2em;
            line-height: 0.75em;
        }
    }
}

/*
Header layout
------------------
*/

#headerWrapper {
    width: 100%;
    z-index: 100;
    background: darken($color-dark-blue, 8%);
    color: $color-white;
}

header {
    padding: 0px 0px 0px 0px;
    margin-bottom: 0px;
    text-align: left;
    @include user-select(none);
}

a#headerLogo {
    display: block;
    position: relative;
    width: 100%;

    img {
        display: block;
        margin: 0 auto;

        &.fullLogo {
            display: none;
        }

        &.logoImage {
            position: absolute;
            top: 0px;
            left: 15%;
            right: 15%;
            max-width: 180px;
            z-index: 100;
        }
    }
}


.bookingFlowPage {
    #headerWrapper {
        background: darken($color-dark-blue, 8%);
        /*position: fixed;*/
        top: 0px;
        left: 0px;
    }

    a#headerLogo {
        display: block;
        font-size: 16px;
        padding: 8px 2px;
        text-decoration: none !important;

        img {
            &.logoImage {
                display: none;
                margin: 0px;
                position: relative;
                top: auto;
                left: 0;
                right: auto;
                max-width: 50px;
                z-index: 100;
            }
        }

        span.underline {
            text-transform: uppercase;
        }

        &:hover span.underline {
            text-decoration: underline;
        }
    }
}


.logoFadeImage {
    opacity: 0.4;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    pointer-events: none;
}

#nav {
    display: block;
    width: 100%;
    margin: 0;

    .mobileNav body & {
        min-height: 100% !important;
        width: 81%;
        position: absolute;
        top: 0px;
        left: -81%;
        z-index: 1;
        background: $color-dark-purple url("/Content/images/common/mobile/menu-side-shadow-tile.png") right top repeat-y;
    }
}

.mobileNav.mobileMenuActive body {
    height: 100%;
    overflow: hidden;

    #viewportWrapper {
        height: 100%;
        overflow: hidden;
    }

    #siteWrapper {
        position: absolute;
        left: 80%;
    }

    #menuArea {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        color: $color-white;
        background: $color-dark-purple url("/Content/images/common/mobile/menu-side-shadow-tile.png") right top repeat-y;
    }
}

#headerSocialLinks {
    display: none;

    dl {
        margin: 0px !important;
    }
}

/* Menu layout */

#menuArea dl.menuAreas {
    margin: 0px 0px 10px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 18px;
    line-height: 20px;
    border-bottom: 1px solid rgba(255,255,255,0.1);

    a {
        text-decoration: none;
    }

    dt {
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        font-weight: normal;

        a {
            display: block;
            position: relative;
            margin: 0px 0px 0px 0px;
            padding: 10px 15px 10px 10px;
            background: $color-dark-purple url("/Content/images/common/mobile/menu-side-shadow-tile.png") right top repeat-y;
            border-top: 1px solid rgba(255,225,255,0.1);
            border-bottom: 1px solid rgba(0,0,0,0.4);
            color: $color-yellow;
            cursor: pointer;

            .expander {
                position: absolute;
                top: 10px;
                right: 15px;
                -webkit-tap-highlight-color: rgba(0,0,0,0);

                &:before {
                    font-family: $font-icon;
                    font-size: 24px;
                    font-weight: normal;
                    content: "\f107"; /* fa-angle-down */
                }
            }

            &.withSubmenu {
                padding: 10px 30px 10px 10px;
            }

            &.open {
                background-color: lighten($color-dark-purple, 10%);
                border-bottom: 1px solid transparent;

                .expander {
                    &:before {
                        content: "\f106"; /* fa-angle-up */
                    }
                }
            }
        }
    }

    dd.submenu {
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 15px 0px;
        background: lighten($color-dark-purple, 10%) url("/Content/images/common/mobile/menu-side-shadow-tile.png") right top repeat-y;
        border-bottom: 1px solid rgba(0,0,0,0.4);
        overflow: hidden;
        font-size: 16px;
        line-height: 18px;

        dl.overview {
            margin: 0px 0px 0px 0px;
            padding: 10px 0px 5px 0px;

            dd a {
                display: block;
                margin: 5px 15px 5px 0px;
                padding: 0px 0px 0px 0px;
                font-weight: normal;
                color: $color-yellow;
            }
        }

        dl.column {
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            width: 100%;

            dd {
                margin: 0px 0px 0px 0px;
                padding: 0px 0px 0px 0px;

                dl.sections {
                    margin: 0px 0px 0px 0px;
                    padding: 0px 0px 0px 0px;

                    dd.section {
                        margin: 0px 0px 0px 0px;
                        padding: 5px 0px 5px 0px;

                        dl.sectionLinks {
                            margin: 0px 0px 0px 0px;
                            padding: 0px 0px 0px 0px;

                            dt.sectionTitle {
                                display: block;
                                margin: 8px 15px 8px 20px;
                                padding: 0px 0px 0px 0px;
                                color: lighten($color-purple, 40%);
                                font-weight: normal;
                                text-transform: uppercase;
                            }

                            dd a {
                                display: block;
                                margin: 8px 15px 8px 20px;
                                padding: 0px 0px 0px 0px;
                                font-weight: normal;
                                color: $color-yellow;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*
General layout
------------------
*/

a, a:visited {
    color: $link-color;
    text-decoration: underline;
}

a:focus, .no-touch a:hover {
    color: $link-hover-color;
    cursor: pointer;
    text-decoration: underline;
}

#miscTags {
    display: none;
}

#contentMask {
    margin: 0px;
    overflow: hidden;
}

#contentWrapper {
    margin: 0px;
    padding: 0px 15px 40px 15px;
    text-align: center;
    z-index: 2;

    .page-bear & {
        padding: 0 15px 0 15px;
    }

    body.homepage &, body.prizePage & {
        padding: 30px 15px 0px 15px;
    }

    body.subpage & {
        padding-top: 180px;
    }

    body.modalPage & {
        padding-top: 20px;
    }
}

.modal body #contentWrapper {
    height: auto !important;
    padding: 10px 10px 0px 10px !important;
    text-align: left;
}

#contentWrapperSpacer {
    display: block;
    height: 30px;
}

//feature sections are sorted out at the end of the General Layout section
.sectionWrapper {
    margin: 0px -15px;
    text-align: center;
    position: relative;

    &.topSectionExtraSpace {
        margin-bottom: 35px;
    }

    &.reduceSpaceAbove {
        margin-top: -25px;
    }

    &.noFeatureImage {
        padding-top: 175px;
        padding-left: 15px;
        padding-right: 15px;

        &.noTopHeader {
            padding-top: 75px;
        }
    }

    section {
        margin-left: 15px;
        margin-right: 15px;
    }
}

section {
    text-align: left;
}

// paragraph styles
p {
    margin: 0px 0px 18px 0px;
    font-size: 1.1em;
    line-height: 1.5em;

    &.largeText {
        font-size: 1.25em;
        line-height: 1.65em;
    }

    &.hugeText {
        font-size: 1.5em;
        line-height: 2em;
    }
}

nav.breadcrumb {
    margin: 0px 0px 4px 0px;
    color: $breadcrumb-color;
    font-family: $font-heading;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;

    a {
        color: $breadcrumb-link-color;

        &:visited {
            color: $breadcrumb-link-color;
        }

        &:hover {
            color: $breadcrumb-link-hover-color;
        }
    }
}

h1 {
    position: relative;
    margin: 0px 0px 10px 0px;
    padding: 0px 0px 0px 0px;
    font-family: $font-heading;
    font-weight: normal;
    font-size: 40px;
    line-height: 47px;
    color: $text-main-heading-color;
    text-transform: uppercase;
}

h2 {
    margin: 35px 0px 7px 0px;
    padding: 0px 0px 0px 0px;
    font-family: $font-heading;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: $text-heading-color;
    text-transform: uppercase;
}

h3 {
    margin: 35px 0px 7px 0px;
    padding: 0px 0px 0px 0px;
    font-family: $font-heading;
    font-weight: normal;
    font-size: 33px;
    line-height: 38px;
    color: $text-heading-color;
}

h4 {
    margin: 25px 0px 7px 0px;
    padding: 0px 0px 0px 0px;
    font-family: $font-heading;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    color: $text-heading-color;
}

.modal {
    h1, h2, h3, h4, a, a:visited {
        color: $color-white !important;
        text-transform: none;
    }

    h1 {
        font-size: 30px;
        line-height: 38px;
    }

    h2 {
        font-size: 24px;
        line-height: 30px;
    }

    h3 {
        font-size: 20px;
        line-height: 26px;
    }

    a, a:visited {
        color: lighten($color-pink, 20%) !important;
    }

    a:hover {
        color: $color-dark-blue !important;
    }

    p, ul li, ol li {
        font-size: 18px;
        line-height: 26px;
    }

    ul li:before {
        color: $color-white;
    }

    .stepIndicator {
        background: $color-white;
        color: $color-dark-blue;
        display: inline-block;
        font-size: 0.75em;
        line-height: 1em;
        padding: 3px;
        text-transform: uppercase;
    }
}


.imageContainer {
    overflow: hidden;
}


@keyframes bearTextIn {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.titleRow {
    padding: 20px 0 !important;
    h1, h2, h3, h4, h5, h6 {
        margin: 0 !important;
        padding: 0 !important;
    }
}


//feature areas
.sectionWrapper {
    margin: 0px -15px;
    text-align: center;

    &.p-relative {
        position: relative;
    }
    // zigzag borders
    &.zigzagBottomBorder {
        padding-bottom: 25px;
        position: relative;

        &:after {
            background: linear-gradient(-45deg, #ffffff 16px, transparent 0), linear-gradient(45deg, #ffffff 16px, transparent 0);
            background-position: left bottom;
            background-repeat: repeat-x;
            background-size: 12px 22px;
            content: " ";
            display: block;
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 32px;
        }

        &.zzbodyBGColor:after {
            background: linear-gradient(-45deg, $body-bg-color 16px, transparent 0), linear-gradient(45deg, $body-bg-color 16px, transparent 0);
            background-position: left bottom;
            background-repeat: repeat-x;
            background-size: 12px 22px;
        }

        &.zzFooterBGColor:after {
            background: linear-gradient(-45deg, $footer-bg-color 16px, transparent 0), linear-gradient(45deg, $footer-bg-color 16px, transparent 0);
            background-position: left bottom;
            background-repeat: repeat-x;
            background-size: 12px 22px;
        }
    }

    section {
        margin-left: 15px;
        margin-right: 15px;
    }

    &.blackBG {
        background: black;
    }

    &.featurePanelImgBg {
        padding-top: 300px;

        @at-root {
            .csstransforms3d .overlayTitle {
                transform: translateY(70px);
                animation: bearTextIn 2s forwards ease;
                animation-delay: 1.1s;
                opacity: 0;
            }
        }

        ~ .overlayTitle {
            position: absolute;
            z-index: 3;
            top: 200px;
            width: 80%;
            left: 0;
            right: 0;
            margin: auto;
        }

        &:after {
            content: "\0020";
            background: rgba(0,0,0,.4);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }


    &.featurePanel {
        //overall feature styling
        .featurePanelMobileImage {
            overflow: hidden;
            text-align: right;

            img {
                width: 220%;
                max-width: none;
            }
        }

        &.contentLeft {
            .featurePanelMobileImage {
                img {
                    float: right;
                }
            }
        }

        .featurePanelContent {
            padding: 20px 0px 5px 0px;

            h2 {
                margin-top: 0px;

                .smallText {
                    display: block;
                }
            }

            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        //which side is the content?
        &.contentLeft {
            .featurePanelContent {
                //float: left;
            }
        }
        //do we need light or dark text?
        &.lightBG {
            h1, h2, h3, h4, p, ul li, ol li {
                color: $color-dark-blue;
            }

            a, a:visited {
                color: $color-dark-blue;
            }

            a:focus, a:hover {
                color: $color-pink;
            }

            a.nextButton, a.nextButton:visited {
                background: $link-color;
                color: $color-dark-blue;

                &:focus, .no-touch &:hover {
                    background: #ffffff;
                    color: $color-dark-blue;
                }
            }

            p.reminderMessage {
                text-transform: uppercase;
                font-size: 0.9em;

                i {
                    color: $color-pink;
                    font-size: 0.75em;
                    line-height: 0.9em;
                }
            }
        }

        &.darkBG {
            h1, h2, h3, h4, p, ul li, ol li {
                color: $color-white;
            }

            a, a:visited {
                color: $color-yellow;
            }

            a:focus, .no-touch a:hover {
                color: $color-white;
            }

            a.nextButton, a.nextButton:visited {
                background: #ffd61d;
                color: #441656;

                strong:before {
                    color: #441656;
                }

                &:focus, .no-touch &:hover {
                    background: #ffffff;
                    color: $color-pink;

                    strong:before {
                        color: $color-pink;
                    }
                }
            }
        }
        //white background
        &.whiteBG {
            background: $color-white;
            padding: 35px 0px 35px 0px;

            &.reducedTopPadding {
                padding-top: 15px;
            }

            &.noTopPaddingMobile {
                padding-top: 0px;
            }

            h3 {
                margin-top: 15px;
            }
        }
        //blue background
        &.blueBG {
            background: $color-blue;
            padding: 35px 0px 35px 0px;

            &.reducedTopPadding {
                padding-top: 15px;
            }

            &.noTopPaddingMobile {
                padding-top: 0px;
            }

            h3 {
                margin-top: 15px;
            }
        }
        //ldc background
        &.ldcBG {
            background: #fad124;
            padding: 35px 0px 35px 0px;

            &.reducedTopPadding {
                padding-top: 15px;
            }

            &.noTopPaddingMobile {
                padding-top: 0px;
            }

            h3 {
                margin-top: 15px;
            }
        }
        //purple background
        &.purpleBG {
            background: $color-darker-purple;
            padding: 35px 0px 35px 0px;

            &.reducedTopPadding {
                padding-top: 15px;
            }

            &.noTopPaddingMobile {
                padding-top: 0px;
            }

            &.thinBorder {
                border-top: 1px solid rgba(255,255,255,0.15);
                margin-top: 30px;
            }

            h3 {
                margin-top: 15px;
            }
        }
        //purple space background
        &.purpleSpaceBG {
            background: #481554 url("/Content/images/feature-panels/purple-space/purple-space-bg.jpg") top right no-repeat;
            background-size: cover;
            padding: 35px 0px 35px 0px;
        }

        &.orangeGradientBG {
            background: #00538a url("/Content/images/feature-panels/orange-fade/orange-fade.jpg") top right no-repeat;
            background-size: cover;
            padding: 35px 0px 35px 0px;

            img.breakOutImage {
                margin: -20px -20% 0px -20%;
                width: 140%;
                max-width: none;
            }
        }
        //light blue fade gradient background
        &.blueGradientBG {
            background: #00538a url("/Content/images/feature-panels/light-blue-fade/light-blue-fade-bg.jpg") top right no-repeat;
            background-size: cover;
            padding: 35px 0px 35px 0px;

            img.breakOutImage {
                margin: -20px -20% 0px -20%;
                width: 140%;
                max-width: none;
            }

            a.nextButton {
                background: $color-yellow;
                color: #002748;

                & strong:before {
                    color: #002748;
                }

                &:focus, .no-touch &:hover {
                    background: #ffffff;
                    color: #002748;
                }
            }

            ul {
                li {
                    &:before, &:after {
                        display: none;
                    }

                    strong {
                        &:before {
                            font-family: $font-icon;
                            font-weight: normal;
                            content: '\f006';
                            color: $color-yellow;
                            font-size: 70%;
                            margin-left: -20px;
                            margin-right: 8px;
                        }

                        &:after {
                            font-family: $font-icon;
                            font-weight: normal;
                            content: '\f006';
                            color: $color-yellow;
                            font-size: 70%;
                            margin-right: -20px;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
        //competition breakout
        &.competitionPrize {
            .sectionBreakOutImage {
                margin-top: -80px;
            }

            .fullWidthBreakoutFeatureImage {
                margin: 0px -7.5%;
                max-width: none;
                width: 115%;
            }
        }
    }
}

// Golden tickets buttons

@keyframes twinkle {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    35% {
        opacity: 0;
    }
    47% {
        opacity: .8;
    }
    60% {
        opacity: .2;
    }
    60% {
        opacity: 1;
    }
    77% {
        opacity: .1;
    }
    92% {
        opacity: .8;
    }
    100% {
        opacity: 0;
    }
}

.golden-ticket-btn--layout {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 30px;
    margin: 30px 0;
    .golden-ticket-btn--layout--column {
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        width: 100%;
        .golden-ticket-btn {
            display: flex;
            position: relative;
            z-index: 2;
            flex-direction: column;
            width: 200px;
            height: 125px;
            justify-content: center;
            align-items: center;
            filter: drop-shadow(8px 5px 5px rgba(black, 0.5));
            will-change: transform;
            color: #8c1d7d !important;
            text-decoration: none !important;
            font-weight: bold !important;
            font-size: 20px;
            transition: color 250ms linear;
            padding-top: 8px;
            box-sizing: border-box;
            strong {
                font-size: 28px;
                font-weight: bold !important;
                text-transform: uppercase;
                transform: rotate(-5deg);
            }
            span {
                display: block;
                transform: rotate(-5deg);
            }
            .sparkles {
                display: contents;
                i {
                    pointer-events: none;
                    display: block;
                    position: absolute;
                    z-index: +1;
                    width: 50px;
                    height: 50px;
                    margin: -25px 0 0 -25px;
                    background: transparent url("/Content/images/sparkle.svg") no-repeat;
                    background-size: 100% 100%;
                    animation: twinkle 5s infinite ease-in-out;
                    &:nth-child(1) {
                        top: 30%;
                        left: 10%;
                        transform: rotate(4deg) scale(.9);
                    }
                    &:nth-child(2) {
                        top: 85%;
                        left: 15%;
                        transform: rotate(29deg) scale(.7);
                        animation-duration: 3.5s;
                        animation-delay: 1s;
                    }
                    &:nth-child(3) {
                        top: 15%;
                        left: 85%;
                        transform: rotate(17deg) scale(1);
                        animation-duration: 3s;
                    }
                    &:nth-child(4) {
                        top: 70%;
                        left: 90%;
                        transform: rotate(42deg) scale(.8);
                        animation-duration: 3.8s;
                        animation-delay: .3s;
                    }
                    &:nth-child(5) {
                        top: 13%;
                        left: 35%;
                        transform: rotate(9deg) scale(.6);
                        animation-duration: 3.2s;
                    }
                    &:nth-child(6) {
                        top: 90%;
                        left: 55%;
                        transform: rotate(33deg) scale(.75);
                        animation-duration: 2.8s;
                        animation-delay: .1s;
                    }
                }
            }
            &:before {
                display: block;
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: transparent url("/Content/images/ticket-button-bg.svg") no-repeat;
                background-size: 100% 100%;
                will-change: transform;
                transition: transform 1s ease-in-out;
            }
            &:hover, &:focus {
                color: $color-pink !important;
                &:before {
                    transform: scale(1.05);
                    transition-duration: 300ms;
                }
            }
        }
        .golden-ticket-btn--layout--pack-shot {
            display: block;
            margin: auto 0 0;
            width: 100%;
            max-width: 400px;
            height: auto;
        }
    }
    @media(min-width: 400px) {
        flex-direction: row;
        align-items: stretch;
    }
}

// Split layout

.split-layout {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    .split-layout--section {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        min-height: 56.25vw;
        .split-layout--section--visual {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .split-layout--section--content {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            box-sizing: border-box;
            width: 100%;
            min-height: 100%;
            padding: 15px 15px 30px;
            background: linear-gradient(0deg, rgba(0,0,0,0.855) 10%, rgba(229,229,229,0) 100%);
            & > *:last-child {
                margin-bottom: 0;
            }
            .nextButton {
                margin-bottom: 0;
            }
        }
    }
}

/*
Slideshows
------------------
*/

.slideshow {
    display: inline-block;
    margin: 0px;
    width: 100%;

    .slidesContainer {
        position: relative;
        padding: 0px;
    }

    &.slideshowOver .slidesContainer {
    }

    .slideshowNavigation {
        display: none;
    }
}

.js body .slideshow {
    .slidesContainer {
        .slide {
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            padding: 0;
            width: 100%;

            &.lastSlide {
                position: relative !important;
                top: auto !important;
                left: auto !important;
            }

            a {
                position: relative;
                display: block;

                img {
                    margin: 0;
                    border: 0;
                    width: 100%;
                    vertical-align: bottom;
                    cursor: -webkit-zoom-in;
                }
            }
        }

        .slideshowCaption {
            display: none;
            position: absolute;
            bottom: 2px;
            left: 0px;
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            color: $color-white;
            z-index: 60;
            width: 100%;

            br {
                display: none;
            }

            strong {
                display: block;
                margin: 0px;
                padding: 2px 10px 0px 10px;
                font-size: 14px;
                font-size: 16px;
                background-color: $color-black;
                background-color: rgba(0,0,0,0.5);
            }

            span {
                display: block;
                margin: 0px;
                padding: 2px 10px 0px 10px;
                font-size: 14px;
                font-size: 16px;
                background-color: $color-black;
                background-color: rgba(0,0,0,0.5);
            }

            em {
                display: block;
                margin: 0px;
                padding: 0px 10px 2px 10px;
                font-size: 11px;
                font-size: 13px;
                background-color: $color-black;
                background-color: rgba(0,0,0,0.5);
            }
        }
    }

    .slideshowNavigation {
        display: block !important;
        margin: 5px 0px 0px 0px;
        height: 20px;
        font-size: 20px;
        line-height: 20px;
        text-align: center;

        a, a:visited {
            display: inline-block;
            margin: 0px 5px 0px 5px;
            overflow: hidden;
            text-decoration: none;
            cursor: pointer;
            color: $color-white;
        }

        a:hover {
            color: $color-purple;
        }

        a.navOn, a.navOn:visited, a.navOn:hover {
            color: $color-white !important;
        }

        a.loading {
            visibility: hidden;
        }

        position: relative;
        bottom: 166px;
        z-index: 100;
    }

    &.slideshowOver .slideshowCaption {
        display: block !important;
    }
}

/*
Tables
------------------
*/

div.wideTableWrapper {
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding: 0px 0px 1px 0px;
    overflow: auto;
}

table.genericTable {
    margin: 15px 0px 15px 0px;
    padding: 0px;
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    background-color: lighten($color-dark-blue, 20%);
    border-left: 1px solid lighten($color-dark-blue, 20%);

    caption {
        padding: 5px 10px 5px 10px;
        text-align: left;
        font-weight: normal;
        background-color: lighten($color-dark-blue, 10%);
        color: $color-white;
    }

    thead th {
        padding: 5px 10px 5px 10px;
        margin: 0px;
        font-size: 13px;
        line-height: 17px;
        font-weight: normal;
        color: $color-white;
        vertical-align: top;
        border-bottom: 1px solid lighten($color-dark-blue, 25%);
        border-left: 1px solid lighten($color-dark-blue, 25%);
        text-align: left;
    }

    tbody {
        th {
            padding: 5px 10px 5px 10px;
            margin: 0px;
            border-bottom: 1px solid lighten($color-dark-blue, 25%);
            border-left: 1px solid lighten($color-dark-blue, 25%);
            font-weight: normal;
            vertical-align: top;
            text-align: left;

            .smallText {
                font-weight: normal;
                color: $color-white;
            }
        }

        td {
            padding: 5px 10px 5px 10px;
            margin: 0px;
            border-bottom: 1px solid lighten($color-dark-blue, 25%);
            border-left: 1px solid lighten($color-dark-blue, 25%);
            font-weight: normal;
            vertical-align: top;
            text-align: left;

            &.featured {
                font-weight: normal;
                color: $color-pink;
            }
        }
    }
}

.googleMap {
    width: 100%;
    height: 250px;
    border: 1px solid $color-black;
    margin: 20px 0px 30px 0px;

    iframe {
        width: 100%;
        height: 250px;
    }
}
/*
Gallery
------------------
*/

.gallery {
    div {
        padding-bottom: 1.5%;

        img {
            box-sizing: border-box;
            overflow: hidden;
            border: 3px solid #f9f3ea;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);

            .no-touch &:hover {
                border: 3px solid #000;
            }
        }
    }
}

/*
Forms and form styles
---------------------
*/


.codeLetterSpace {
    letter-spacing: 4px;
}

#contentWrapper {

    @media(max-width:768px) {
        .pageTitleHeading {
            h1 {
                font-size: 30px;
                line-height: 1.2;
            }
        }
    }

    .bear {

        .nextButton {
            font-weight: normal;
            background-color: #e74315 !important;
            color: white !important;
            border: 2px solid #e74315;

            &:hover {
                background: transparent !important;
                color: white;
                border: 2px solid #e74315;
            }
        }

        .singleLineFormWrap {
            max-width: 600px;
            margin: auto;

            input {
                width: auto;
            }

            button, .redeemBtn {
                cursor: pointer;
                font-weight: normal;
                background-color: #e74315;
                color: white;
                border: 2px solid #e74315;

                &:hover {
                    background: transparent;
                    color: white;
                    border: 2px solid #e74315;
                }
            }

            .redeemBtn {
                margin-top: 30px;
            }

            input, button, .redeemBtn {
                outline: 0;
                text-align: center;
                padding: 12px;
                font-size: inherit;
                border-radius: 0;
            }


            form {
                background: none;

                .text-danger {
                    display: block;
                    margin: 15px 0 15px;
                    color: lighten(red,25%);
                    font-size: 15px;

                    @media(min-width:736px) {
                        font-size: inherit;
                    }
                }
            }

            .copyBtn, .redeemBtn {
                padding: 7px 15px;
                font-weight: normal;
                font-size: inherit;
                font-family: inherit;
                text-decoration: none !important;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            .is-copied {
                margin-top: 30px;
            }
        }
    }
}


input[type="button"], input[type="reset"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0px 3px 5px 0px;
    padding: 0px;
    vertical-align: middle;
    border: 1px solid #c5c5c5;
    background: none,-webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#f2f4f3));
    background: none,-moz-linear-gradient(center top, #fff 0%, #f2f4f3 100%);
    background: none,linear-gradient(to bottom, #fff 0%, #f2f4f3 100%);
    width: 21px;
    height: 21px;
    cursor: pointer;
    outline: none;
}

body.noTouch input[type="button"]:hover, body.noTouch input[type="reset"]:hover {
    background-image: none;
    background-color: $color-white;
}

input[type="button"]:disabled, input[type="reset"]:disabled {
    background: lighten($color-dark-blue, 10%) !important;
    opacity: 0.5 !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
        display: none;
    }

    margin: 0px 5px 0px 0px;
    text-align: left;
    color: $color-black;
    @include border-radius(4px);
    border: 1px solid #c5c5c5;
    padding: 8px 18px 8px 12px;
    background: url("/Content/images/common/forms/select-arrow.png") right no-repeat, -webkit-gradient(linear, 0 0, 0 100%, from($color-white), to(#f2f4f3));
    background: url("/Content/images/common/forms/select-arrow.png") right no-repeat, -moz-linear-gradient(center top, $color-white 0%, #f2f4f3 100%);
    background: url("/Content/images/common/forms/select-arrow.png") right no-repeat, linear-gradient(to bottom, $color-white 0%, #f2f4f3 100%);
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    outline: none;
    text-indent: 0.01px; /* Removes default arrow from firefox*/
    text-overflow: ""; /* Removes default arrow from firefox*/
    .hires body & {
        background: url("/Content/images/common/forms/select-arrow-2x.png") right no-repeat, -webkit-gradient(linear, 0 0, 0 100%, from($color-white), to(#f2f4f3));
        background: url("/Content/images/common/forms/select-arrow-2x.png") right no-repeat, -moz-linear-gradient(center top, $color-white 0%, #f2f4f3 100%);
        background: url("/Content/images/common/forms/select-arrow-2x.png") right no-repeat, linear-gradient(to bottom, $color-white 0%, #f2f4f3 100%);
        background-size: 12px 9px, cover;
    }

    &:disabled {
        opacity: 0.5 !important;
    }
}


input:focus, textarea:focus {
    &::-webkit-input-placeholder {
        color: transparent !important;
    }

    &::-moz-placeholder {
        color: transparent !important;
    }

    &:-ms-input-placeholder {
        color: transparent !important;
    }

    &:-moz-placeholder {
        color: transparent !important;
    }
}

#contentWrapper {
    form {
        background: lighten($color-purple, 20%);
        padding: 0px;

        div.formArea {
            background: $color-purple;
            border-top: 1px lighten($color-purple, 20%) solid;
            border-left: 1px lighten($color-purple, 20%) solid;
            border-right: 1px lighten($color-purple, 20%) solid;
            padding: 20px 20px 25px 20px;

            label {
                font-size: 1.25em;
                line-height: 1.5em;
                text-transform: uppercase;
            }

            div {
                input[type="text"], input[type="email"] {
                    border: 1px solid lighten($color-purple, 20%);
                    @include border-radius(4px);
                    color: $color-dark-blue;
                    font-size: 20px;
                    padding: 8px;
                    text-align: center;
                    width: 50%;

                    &.input-validation-error {
                        background: lighten($color-form-error-style, 18%);
                        border: 1px solid $color-form-error-style;
                    }
                }

                select {
                    &.input-validation-error {
                        background-color: lighten($color-form-error-style, 18%);
                        border: 1px solid $color-form-error-style;
                    }
                }

                input[type="checkbox"] {
                    transform: scale(2);
                    -webkit-transform: scale(2);
                }

                &.wideField input[type="text"],
                &.wideField input[type="email"] {
                    width: 75%;
                }

                &.narrowField input[type="text"],
                &.narrowField input[type="email"] {
                    width: 25%;
                }

                p.helpText {
                    font-size: 1em;
                    margin-top: 10px;
                }
            }

            .field-validation-error {
                color: $color-form-error-style;
                display: block;
                margin-top: 8px;
            }
        }

        button {
            background: $color-yellow;
            border: none;
            @include border-radius(4px);
            color: $color-dark-blue;
            font-size: 24px;
            line-height: 26px;
            margin: 20px auto;
            padding: 15px 25px;
            text-transform: uppercase;

            &:hover {
                background: $color-pink;
                color: $color-white;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}

/*
Main features
---------------------------
*/

#mainFeatureContainer {
    position: relative;
    margin: 0px; // 5px under header
    width: 100%;
    z-index: 1;
    @include user-select(none);

    #featureContainer {
        position: relative;
        margin: 0px;
        z-index: 1;
        overflow: hidden;

        img {
            margin: 140px -25% 0px -25%;
            max-width: none;
            width: 150%;
        }

        .bg-video {
            margin-top: 140px;
            position: relative;
            width: 100%;
            height: 95.78vw;
            iframe {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: calc(100% * 1.77778);
                height: 100vw;
            }
        }

        #featureOverlay {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 150px;
            z-index: 15;
            /* IE9 SVG, needs conditional override of 'filter' to 'none' */
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFkMTc0YyIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxZDE3NGMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(top, rgba(29,23,76,0) 0%, rgba(29,23,76,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(29,23,76,0)), color-stop(100%,rgba(29,23,76,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top, rgba(29,23,76,0) 0%,rgba(29,23,76,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top, rgba(29,23,76,0) 0%,rgba(29,23,76,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top, rgba(29,23,76,0) 0%,rgba(29,23,76,1) 100%); /* IE10+ */
            background: linear-gradient(to bottom, rgba(29,23,76,0) 0%,rgba(29,23,76,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001d174c', endColorstr='#1d174c',GradientType=0 ); /* IE6-8 */
        }

        #featureOverlayTop {
            position: absolute;
            top: 139px;
            left: 0px;
            width: 100%;
            height: 77px;
            z-index: 15;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1d174c+0,1d174c+100&amp;1+0,0+100 */
            /* IE9 SVG, needs conditional override of 'filter' to 'none' */
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFkMTc0YyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxZDE3NGMiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(top, rgba(29,23,76,1) 0%, rgba(29,23,76,0) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(29,23,76,1)), color-stop(100%,rgba(29,23,76,0))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top, rgba(29,23,76,1) 0%,rgba(29,23,76,0) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top, rgba(29,23,76,1) 0%,rgba(29,23,76,0) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top, rgba(29,23,76,1) 0%,rgba(29,23,76,0) 100%); /* IE10+ */
            background: linear-gradient(to bottom, rgba(29,23,76,1) 0%,rgba(29,23,76,0) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d174c', endColorstr='#001d174c',GradientType=0 ); /* IE6-8 */
        }
    }

    h1.mainFeatureHeaderCTA {
        position: absolute;
        bottom: 0px;
        left: 0px;
        margin-bottom: 0px;
        text-align: center;
        width: 100%;
        z-index: 100;

        a, a:visited {
            color: $color-white;
            display: block;
            margin: 0px auto;
            text-decoration: none;
            max-width: 350px;
        }

        i {
            display: block;
        }

        .largeWord {
            display: block;
            font-size: 2.85em;
            line-height: 1em;

            @media (max-width:470px) {
                font-size: 1.65em;
            }
        }
    }
}

#topBGImageContainer {
    display: none;
}




/*
==============================================
pulse
==============================================
*/

.pulse {
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.7);
        opacity: 0.7;
    }

    50% {
        transform: scale(1.3);
        opacity: 1.3;
    }

    100% {
        transform: scale(0.7);
        opacity: 0.7;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.7);
        opacity: 0.7;
    }

    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0.7);
        opacity: 0.7;
    }
}



/*
Attraction listings
------------------
*/

ul.attractionListingSinglePack {
    display: flex;
    justify-content: center;
}

ul.attractionListing, ul.attractionListingSinglePack {
    width: 102%;


    li {
        display: block;
        float: left;
        margin: 0 2.5% 10px 0;
        padding: 0px;
        width: 100%;

        &:before {
            content: "";
            display: none;
        }

        .attractionLink {
            display: block;
            background: #e8e8e8;
            box-shadow: #fff -1px -1px 1px, #c7c7c7 1px 1px 1px, #e8e8e8 3px 3px 9px;
            color: $color-dark-blue;
            display: block;
            height: 100%;
            margin: 0px;
            padding: 0px;
            text-align: left;
            width: 98%;
            cursor: not-allowed;

            @include border-radius(10px);

            span {
                display: block;

                &.attractionContent {
                    padding: 10px;
                }

                .attractionLogo {
                    margin: 0px auto;
                    width: 40%;
                }

                .attractionName {
                    display: none;
                }
            }

            &.inactiveAttraction {
                background: lighten(#e8e8e8, 3%);
                opacity: 0.4;
            }

            &.altonTowers {
                background: $attr-atr-bg;
                color: #fff !important;
            }

            &.blackpoolTower {
                background: $attr-bpt-bg;
                color: #fff !important;
            }

            &.bearGrylls {
                background: $attr-bga-bg;
                color: #fff !important;
            }

            &.chessington {
                background: $attr-cwoa-bg;
                color: #fff !important;
            }

            &.legolandWindsor {
                background: $attr-llw-bg;
                color: #fff !important;
            }

            &.londonEye {
                background: $attr-le-bg;
                color: #fff !important;
            }

            &.theDungeons {
                background: $attr-td-bg;
                color: #fff !important;
            }

            &.jurassicSkyline {
                background: $attr-js-bg;
                color: #fff !important;
            }

            &.legolandDiscoveryCentre {
                background: $attr-ldc-bg;
            }

            &.madameTussauds {
                background: $attr-mt-bg;
                color: #fff !important;
            }

            &.sealife {
                background: $attr-sl-bg;
                color: #fff !important;
            }

            &.shreksAdventure {
                background: #7aa321;
            }

            &.thorpePark {
                background: #5436AE;
                color: #fff !important;
            }

            &.warwickCastle {
                background: $attr-wc-bg;
            }

            &.bearGrylls {
                background: #982000;
            }

            &.blackpoolTowerCircus {
                background: #f99a00;
                color: #fff !important;
            }

            &.peterRabbit {
                background: #0033a0;
                color: #fff !important;
            }
        }

        a.attractionLink {
            cursor: pointer;

            &.inactiveAttraction:hover {
                background: darken(#e8e8e8, 6%);
                box-shadow: #e8e8e8 3px 3px 15px;
                cursor: pointer;
            }

            &.altonTowers:hover {
                background: darken($attr-atr-bg, 10%);
            }

            &.blackpoolTower:hover {
                background: darken($attr-bpt-bg, 10%);
            }

            &.bearGrylls:hover {
                background: darken($attr-bga-bg, 10%);
            }

            &.chessington:hover {
                background: darken($attr-cwoa-bg, 10%);
            }

            &.legolandWindsor:hover {
                background: darken($attr-llw-bg, 10%);
            }

            &.londonEye:hover {
                background: darken($attr-le-bg, 10%);
            }

            &.theDungeons:hover {
                background: darken($attr-td-bg, 10%);
            }

            &.jurassicSkyline:hover {
                background: darken($attr-js-bg, 10%);
            }

            &.legolandDiscoveryCentre:hover {
                background: darken($attr-ldc-bg, 10%);
            }

            &.madameTussauds:hover {
                background: darken($attr-mt-bg, 10%);
            }

            &.sealife:hover {
                background: darken($attr-sl-bg, 10%);
            }

            &.shreksAdventure:hover {
                background: darken(#7aa321, 10%);
            }

            &.thorpePark:hover {
                background: darken(#5436AE, 10%);
            }

            &.warwickCastle:hover {
                background: darken($attr-wc-bg, 10%);
            }

            &.bearGrylls:hover {
                background: #731f07;
            }

            &.blackpoolTowerCircus:hover {
                background: darken(#f99a00, 10%);
            }

            &.peterRabbit:hover {
                background: darken(#0033a0, 10%);
            }
        }
    }

    &.smallItems {
        li {
            width: 22.5%;


            a {
                span {
                    .attractionLogo {
                        width: 100%;
                    }

                    .attractionName {
                        display: none;
                    }
                }
            }
        }
    }
}


.panel.activeAttractionBox {
    background-position: center top;
    border-radius: 20px;
    text-align: left;

    ul.suitabilityList {
        font-size: 0.8em;
        text-transform: uppercase;
        text-align: center;

        li {
            padding: 0 0 0 13px;

            &:before {
                font-family: $font-icon;
                font-weight: normal;
                content: '\f006';
                font-size: 70%;
                margin-left: -20px;
                margin-right: 8px;
            }

            &:after {
                font-family: $font-icon;
                font-weight: normal;
                content: '\f006';
                font-size: 70%;
                margin-right: -20px;
                margin-left: 8px;
            }

            .smallText {
                font-size: 0.8em;
            }
        }
    }

    .attrSideLogo {
        text-align: center;

        img {
            display: block;
            max-height: 200px;
            margin: 0px auto !important;

            &#ldc-logo, &#llw-logo {
                margin: 0px auto 5% auto !important;
            }
        }
    }

    .gugfLogoSmall {
        img {
            max-width: 350px;
            width: 100%;
        }
    }

    select {
        font-size: 1.2em;
        line-height: 1.2em;
        margin-bottom: 25px;
        border: 1px solid #c5c5c5;
        padding: 6px 18px 6px 10px;
        background: url("/Content/images/common/forms/select-arrow.png") right no-repeat, -webkit-gradient(linear, 0 0, 0 100%, from($color-white), to(#f2f4f3));
        background: url("/Content/images/common/forms/select-arrow.png") right no-repeat, -moz-linear-gradient(center top, $color-white 0%, #f2f4f3 100%);
        background: url("/Content/images/common/forms/select-arrow.png") right no-repeat, linear-gradient(to bottom, $color-white 0%, #f2f4f3 100%);

        option {
            background-color: #f2f4f3;
        }
    }

    .panel {
        position: relative;
    }

    .panel.priceTypesContainer {
        border-radius: 0px 0px 15px 15px;
        margin: 0px auto;
        width: 100%;

        h2 {
            font-size: 1.4em;
            margin: 2px 0px 25px 0px;
            text-transform: none;
        }

        .panel {
            border: none;

            .panelContent {
                padding-bottom: 12px;

                h2 {
                    font-size: 2em;
                    line-height: 0.7em;
                    margin: 15px 0px 15px 0px !important;
                    text-transform: none;
                }

                .priceAmount {
                    display: block;
                    font-size: 21px;
                    font-weight: bold;
                    line-height: 27px;
                }

                .peopleIcon {
                    img {
                        margin: 0 auto;
                        max-height: 100px;
                    }
                }

                p.priceSelectButton a {
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    border: 2px solid $color-white;
                    box-sizing: border-box;
                    background-color: #dcdcdc;
                    padding: 9px 15px;
                    margin: 0px 0px 10px 0px;
                    font-size: 18px;
                    line-height: 1.4em;
                    color: #190e33;
                    text-decoration: none;
                    text-transform: uppercase;
                    @include border-radius(12px);
                    @include box-shadow (2px, 2px, 7px, rgba(0,0,0,0.33));
                    cursor: pointer;

                    strong {
                        font-family: $font-body;
                        font-weight: 400;
                    }

                    &:focus, .no-touch body &:hover {
                        background-color: $color-white;
                        color: #190e33;
                        text-decoration: none;
                    }
                }

                p.priceSelectButton {
                    margin-bottom: 5px;
                }

                .priceConfirmArea {
                    display: none;

                    p {
                        margin-bottom: 0px;
                    }
                }
            }

            &.panelHighlighted {

                .panelContent {
                    .priceSelectButton {
                        display: none;
                    }

                    .priceConfirmArea {
                        display: block;

                        p {
                            color: #258507;
                            font-weight: bold;

                            a.yesButton, a.noButton {
                                background: #258507;
                                color: $color-white;
                                display: inline-block;
                                padding: 4px 12px;
                                text-decoration: none;
                                @include border-radius(20px);
                            }

                            a.noButton {
                                background: #c61111;
                            }
                        }
                    }
                }
            }
        }
    }
    // feature area attraction styling
    // (colors are defined in the _variables include, with the mixin taking care of the repetitive CSS styles for each one!)
    //	alton towers
    &.altonTowers {
        background: $attr-atr-bg url("/Content/images/attraction-features/2019/alton-towers.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-atr-bg, $attr-atr-text, $attr-atr-highlight, $attr-atr-panel-text, $attr-atr-panel1-bg, $attr-atr-panel2-bg)
    }
    //	chessington
    &.chessington {
        background: $attr-cwoa-bg url("/Content/images/attraction-features/2021/chessington-feature.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-cwoa-bg, $attr-cwoa-text, $attr-cwoa-highlight, $attr-cwoa-panel-text, $attr-cwoa-panel1-bg, $attr-cwoa-panel2-bg)
    }
    //	legoland windsor
    &.legolandWindsor {
        //    background: $attr-llw-bg url("/Content/images/attraction-features/legoland-windsor-starwars.jpg") no-repeat;
        background: $attr-llw-bg url("/Content/images/attraction-features/2020/legoland-windsor-open.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-llw-bg, $attr-llw-text, $attr-llw-highlight, $attr-llw-panel-text, $attr-llw-panel1-bg, $attr-llw-panel2-bg)
    }

    &.legolandWindsor {
        .panel {
            &.priceTypesContainer {
                background: #272A94;

                .priceDecriptionTextArea {
                    p {
                        color: #fff;
                    }
                }
            }

            h2.whosVisitingHeading {
                color: #fff;
            }

            p.termsLine {
                color: #fff;
            }
        }
    }
    //	thorpe park
    &.thorpePark {
        background: $attr-tp-bg url("/Content/images/attraction-features/2023/thorpe-park.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-tp-bg, $attr-tp-text, $attr-tp-highlight, $attr-tp-panel-text, $attr-tp-panel1-bg, $attr-tp-panel2-bg)
    }

    &.thorpePark {
        .panel {
            &.priceTypesContainer {
                background: #2B2B2B;
            }
        }
    }
    //	warwick castle
    &.warwickCastle {
        background: $attr-wc-bg url("/Content/images/attraction-features/2021/warwick-castle.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-wc-bg, $attr-wc-text, $attr-wc-highlight, $attr-wc-panel-text, $attr-wc-panel1-bg, $attr-wc-panel2-bg)
    }

    &.warwickCastle {
        .panel.priceTypesContainer {
            .panelContent {
                h2.largeHeading {
                    color: $attr-wc-text;
                }

                p.smallText {
                    color: $attr-wc-text;
                }
            }
        }
    }
    //	the blackpool tower
    &.blackpoolTower {
        background: $attr-bpt-bg url("/Content/images/attraction-features/2020/blackpool-tower-feature.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-bpt-bg, $attr-bpt-text, $attr-bpt-highlight, $attr-bpt-panel-text, $attr-bpt-panel1-bg, $attr-bpt-panel2-bg)
    }

    &.blackpoolTower {
        .panel {
            &.priceTypesContainer {
                background: #C8008D;
            }
        }
    }
    //	bear grylls
    &.bearGrylls {
        background: url("/Content/images/attraction-features/2022/bear-grylls.jpg") no-repeat;
        background-color: #121a1d;
        background-size: 100%;
        @include attrFeatureStyles($attr-bga-bg, $attr-bga-text, $attr-bga-highlight, $attr-bga-panel-text, $attr-bga-panel1-bg, $attr-bga-panel2-bg)
    }

    &.bearGrylls {
        .panel {
            &.priceTypesContainer {
                background: #333;
                border: none;

                .priceDecriptionTextArea {
                    p {
                        color: #fff;
                    }
                }
            }

            h2.whosVisitingHeading {
                color: #fff;
            }

            p.termsLine {
                color: #fff;
            }
        }
    }
    // coca cola london eye
    &.londonEye {
        background: $attr-le-bg url("/Content/images/attraction-features/2017/london-eye.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-le-bg, $attr-le-text, $attr-le-highlight, $attr-le-panel-text, $attr-le-panel1-bg, $attr-le-panel2-bg)
    }

    &.londonEye {
        .panel.priceTypesContainer {
            .panelContent {
                h2.largeHeading {
                    color: $attr-le-text;
                }

                p.smallText {
                    color: $attr-le-text;
                }
            }
        }
    }
    //	the dungeons
    &.theDungeons {
        background: $attr-td-bg url("/Content/images/attraction-features/2019/the-dungeons.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-td-bg, $attr-td-text, $attr-td-highlight, $attr-td-panel-text, $attr-td-panel1-bg, $attr-td-panel2-bg)
    }
    //	jurassic skyline
    &.jurassicSkyline {
        background: $attr-js-bg url("/Content/images/attraction-features/jurassic-skyline.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-js-bg, $attr-js-text, $attr-js-highlight, $attr-js-panel-text, $attr-js-panel1-bg, $attr-js-panel2-bg)
    }
    //	legoland discovery centre
    &.legolandDiscoveryCentre {
        background: $attr-ldc-bg url("/Content/images/attraction-features/2021/legoland-discovery-centre-feature.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-ldc-bg, $attr-ldc-text, $attr-ldc-highlight, $attr-ldc-panel-text, $attr-ldc-panel1-bg, $attr-ldc-panel2-bg)
    }

    &.legolandDiscoveryCentre {
        .panel {
            &.priceTypesContainer {
                background: #272A94;

                .priceDecriptionTextArea {
                    p {
                        color: #fff;
                    }
                }
            }

            h2.whosVisitingHeading {
                color: #fff;
            }

            p.termsLine {
                color: #fff;
            }
        }
    }
    //	madame tussauds
    &.madameTussauds {
        background: $attr-mt-bg url("/Content/images/attraction-features/2021/madame-tussauds.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-mt-bg, $attr-mt-text, $attr-mt-highlight, $attr-mt-panel-text, $attr-mt-panel1-bg, $attr-mt-panel2-bg)
    }
    //	sealife
    &.sealife {
        background: $attr-sl-bg url("/Content/images/attraction-features/2021/sea-life-feature.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-sl-bg, $attr-sl-text, $attr-sl-highlight, $attr-sl-panel-text, $attr-sl-panel1-bg, $attr-sl-panel2-bg)
    }
    //	shreks adventure
    &.shreksAdventure {
        background: $attr-sa-bg url("/Content/images/attraction-features/2019/shreks-adventure.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-sa-bg, $attr-sa-text, $attr-sa-highlight, $attr-sa-panel-text, $attr-sa-panel1-bg, $attr-sa-panel2-bg)
    }

    &.shreksAdventure {
        .panel {
            &.priceTypesContainer {
                background: #312C28;
            }
        }
    }
    //	the blackpool tower circus
    &.blackpoolTowerCircus {
        background: $attr-bptc-bg url("/Content/images/attraction-features/2022/bptc.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-bptc-bg, $attr-bptc-text, $attr-bptc-highlight, $attr-bptc-panel-text, $attr-bptc-panel1-bg, $attr-bptc-panel2-bg)
    }
    //	peter rabbit
    &.peterRabbit {
        background: $attr-pr-bg url("/Content/images/attraction-features/2023/peter-rabbit-new.jpg") no-repeat;
        background-size: 100%;
        @include attrFeatureStyles($attr-pr-bg, $attr-pr-text, $attr-pr-highlight, $attr-pr-panel-text, $attr-pr-panel1-bg, $attr-pr-panel2-bg)
    }
}
    /*
Booking flow
------------------
*/
.flowSection {
    padding-bottom: 25px;

    &#flowSection2 {
        border-top: 1px solid #cacaca;
    }

    &#flowSection3 {
        border-top: 1px solid #cacaca;
    }

    a.nextButton, a.nextButton:visited {
        background: $color-pink !important;
        color: $color-white !important;
    }

    a.nextButton:hover {
        background: $color-dark-blue !important;
    }

    .stepIndicator {
        width: 100%;
        text-align: center;

        p {
            background: $color-dark-blue;
            color: $color-white !important;
            @include border-radius(32px);
            height: 64px;
            width: 64px;
            font-size: 50px;
            font-weight: bold;
            line-height: 64px;
            margin: 10px auto 20px auto;
            text-align: center;

            i {
                font-size: 40px;
                font-weight: normal;
            }
        }
    }

    &#flowSection2,
    &#flowSection3 {
        .stepIndicator {
            p {
                margin: 30px auto 20px auto;
            }
        }
    }

    p.flowCTA {
        background: transparentize(lighten($color-dark-blue, 65%), 0.1);
        border-radius: 10px;
        color: $color-dark-blue !important;
        font-size: 15px;
        line-height: 21px;
        padding: 7px 11px;
        position: relative;
        width: 66%;
        max-width: 400px;
        margin: -15px auto 0px auto;

        a {
            color: $color-dark-blue !important;
            text-decoration: none;
        }

        i {
            color: lighten($color-dark-blue, 66%) !important;
            font-size: 3em;
            position: absolute;
            left: 50%;
            margin-left: -15px;
            bottom: -25px;
        }
    }

    &.inactive {
        h1, h2, h3, p {
            color: #adadad !important;
        }

        .stepIndicator p {
            background: #adadad;
            color: $color-white !important;
        }
    }

    &.complete {
        h1, h2, h3, p {
            color: $color-green !important;
        }

        h2, h3 {
            display: none;
        }

        .stepIndicator p {
            background: $color-green;
            color: $color-white !important;
        }

        p.flowCTA {
            color: $color-white !important;
        }
    }

    .editButton {
        background: lighten($color-grey, 20%);
        border: 2px solid #fff;
        @include border-radius(12px);
        @include box-shadow(2px, 2px, 8px, rgba(0,0,0,0.25), false);
        color: darken($color-grey, 35%) !important;
        display: inline-block;
        //@include linear-gradient-y(lighten($color-grey, 25%), $color-grey);
        font-size: 0.75em;
        line-height: 1.1em;
        padding: 14px 14px;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
            background: lighten($color-grey, 10%);
            cursor: pointer;
            color: darken($color-grey, 55%) !important;
            //@include linear-gradient-y(lighten($color-grey, 15%), darken($color-grey, 10%));
            text-decoration: none;
        }
    }

    .panel {
        &.summaryPanel {
            box-shadow: 2px 2px 5px rgba(0,0,0,0.33);
            border: none;

            &.altonTowers {
                @include attrSummaryStyles($attr-atr-bg, $attr-atr-summary-cta, $attr-atr-summary-cta-text, $attr-atr-summary-text-color, $attr-atr-summary-heading-bg, $attr-atr-summary-text-bg, $attr-atr-small-text)
            }
            //	chessington
            &.chessington {
                @include attrSummaryStyles($attr-cwoa-bg, $attr-cwoa-summary-cta, $attr-cwoa-summary-cta-text, $attr-cwoa-summary-text-color, $attr-cwoa-summary-heading-bg, $attr-cwoa-summary-text-bg, $attr-cwoa-small-text)
            }
            //	legoland windsor
            &.legolandWindsor {
                @include attrSummaryStyles($attr-llw-bg, $attr-llw-summary-cta, $attr-llw-summary-cta-text, $attr-llw-summary-text-color, $attr-llw-summary-heading-bg, $attr-llw-summary-text-bg, $attr-llw-small-text)
            }

            &.legolandWindsor {
                a.nextButton:hover {
                    background: $attr-llw-summary-cta-text !important;
                    color: $attr-llw-summary-cta !important;
                }
            }
            //	thorpe park
            &.thorpePark {
                @include attrSummaryStyles($attr-tp-bg, $attr-tp-summary-cta, $attr-tp-summary-cta-text, $attr-tp-summary-text-color, $attr-tp-summary-heading-bg, $attr-tp-summary-text-bg, $attr-tp-small-text)
            }
            //	warwick castle
            &.warwickCastle {
                @include attrSummaryStyles($attr-wc-bg, $attr-wc-summary-cta, $attr-wc-summary-cta-text, $attr-wc-summary-text-color, $attr-wc-summary-heading-bg, $attr-wc-summary-text-bg, $attr-wc-small-text)
            }
            //	the blackpool tower
            &.blackpoolTower {
                @include attrSummaryStyles($attr-bpt-bg, $attr-bpt-summary-cta, $attr-bpt-summary-cta-text, $attr-bpt-summary-text-color, $attr-bpt-summary-heading-bg, $attr-bpt-summary-text-bg, $attr-bpt-small-text)
            }

            &.blackpoolTower {
                a.nextButton:hover {
                    background: $color-white !important;
                    color: $attr-bpt-summary-cta !important;
                }
            }
            //	the blackpool tower circus
            &.blackpoolTowerCircus {
                @include attrSummaryStyles($attr-bptc-bg, $attr-bptc-summary-cta, $attr-bptc-summary-cta-text, $attr-bptc-summary-text-color, $attr-bptc-summary-heading-bg, $attr-bptc-summary-text-bg, $attr-bptc-small-text)
            }

            &.blackpoolTowerCircus {
                a.nextButton:hover {
                    background: $color-white !important;
                    color: $attr-bptc-summary-cta !important;
                }
            }
            //	peter rabbit
            &.peterRabbit {
                @include attrSummaryStyles($attr-pr-bg, $attr-pr-summary-cta, $attr-pr-summary-cta-text, $attr-pr-summary-text-color, $attr-pr-summary-heading-bg, $attr-pr-summary-text-bg, $attr-pr-small-text)
            }

            &.peterRabbit {
                a.nextButton:hover {
                    background: $color-white !important;
                    color: $attr-pr-summary-cta !important;
                }
            }
            //	bear grylls
            &.bearGrylls {
                @include attrSummaryStyles($attr-bga-bg, $attr-bga-summary-cta, $attr-bga-summary-cta-text, $attr-bga-summary-text-color, $attr-bga-summary-heading-bg, $attr-bga-summary-text-bg, $attr-bga-small-text)
            }

            &.bearGrylls {
                a.nextButton:hover {
                    background: $color-white !important;
                    color: $attr-bga-summary-cta !important;
                }
            }
            // coca cola london eye
            &.londonEye {
                @include attrSummaryStyles($attr-le-bg, $attr-le-summary-cta, $attr-le-summary-cta-text, $attr-le-summary-text-color, $attr-le-summary-heading-bg, $attr-le-summary-text-bg, $attr-le-small-text)
            }
            //	the dungeons
            &.theDungeons {
                @include attrSummaryStyles($attr-td-bg, $attr-td-summary-cta, $attr-td-summary-cta-text, $attr-td-summary-text-color, $attr-td-summary-heading-bg, $attr-td-summary-text-bg, $attr-td-small-text)
            }

            &.theDungeons {
                a.nextButton:hover {
                    background: $attr-td-summary-cta-text !important;
                    color: $attr-td-summary-cta !important;
                }
            }
            //	jurassic skyline
            &.jurassicSkyline {
                @include attrSummaryStyles($attr-js-bg, $attr-js-summary-cta, $attr-js-summary-cta-text, $attr-js-summary-text-color, $attr-js-summary-heading-bg, $attr-js-summary-text-bg, $attr-js-small-text)
            }
            //	legoland discovery centre
            &.legolandDiscoveryCentre {
                @include attrSummaryStyles($attr-ldc-bg, $attr-ldc-summary-cta, $attr-ldc-summary-cta-text, $attr-ldc-summary-text-color, $attr-ldc-summary-heading-bg, $attr-ldc-summary-text-bg, $attr-ldc-small-text)
            }

            &.legolandDiscoveryCentre {
                a.nextButton:hover {
                    background: $attr-ldc-summary-cta-text !important;
                    color: $attr-ldc-summary-cta !important;
                }
            }
            //	madame tussauds
            &.madameTussauds {
                @include attrSummaryStyles($attr-mt-bg, $attr-mt-summary-cta, $attr-mt-summary-cta-text, $attr-mt-summary-text-color, $attr-mt-summary-heading-bg, $attr-mt-summary-text-bg, $attr-mt-small-text)
            }
            //	sealife
            &.sealife {
                @include attrSummaryStyles($attr-sl-bg, $attr-sl-summary-cta, $attr-sl-summary-cta-text, $attr-sl-summary-text-color, $attr-sl-summary-heading-bg, $attr-sl-summary-text-bg, $attr-sl-small-text)
            }
            //	shreks adventure
            &.shreksAdventure {
                @include attrSummaryStyles($attr-sa-bg, $attr-sa-summary-cta, $attr-sa-summary-cta-text, $attr-sa-summary-text-color, $attr-sa-summary-heading-bg, $attr-sa-summary-text-bg, $attr-sa-small-text)
            }

            &.upgrade {
                @include attrSummaryStyles($attr-upgrade-bg, $attr-atr-summary-cta, $attr-atr-summary-cta-text, $attr-upgrade-summary-text-color, $attr-upgrade-summary-heading-bg, $attr-upgrade-summary-text-bg, $attr-upgrade-small-text);

                a {
                    text-transform: initial;
                    color: $attr-upgrade-small-text;

                    &:hover {
                        color: white;
                    }
                }
            }

            .panelContent {
                padding: 15px;

                h3 {
                    background: $color-dark-blue;
                    @include border-radius(5px 5px 0px 0px);
                    color: #f5eedd;
                    margin: 0px;
                    padding: 7px 0px;
                    width: 100%;
                }

                p {
                    background: rgba(255,255,255,0.5);
                    margin: 0px 0px 1px 0px;
                    padding: 7px 0px;
                    max-width: none !important;
                }

                p.noBackground {
                    background: none;
                    margin: 10px 0px 10px 0px;
                }

                p.smallText {
                    background: none;
                    margin: 12px 0px 1px 0px;
                }

                p.summaryLogo {
                    background: none;

                    img {
                        margin: -15px auto 0px auto;
                        width: 150px;
                    }
                }

                p.summaryLogoUpgrade {
                    background: none;

                    img {
                        margin: 5px auto 10px auto;
                        width: 200px;
                    }
                }
            }
        }
    }
}
    /*
Panel layout
------------------
*/
    @media(max-width:768px) {
        .singularPanel.panel {
            margin: 45px 0;

            ~ .attractionHelp {
                margin-top: 45px;
            }
        }
    }

    .panel {
        background: #d2d1db;
        background: rgba(255,255,255,0.8);
        background: -moz-linear-gradient(top, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.8) 38%, rgba(255,255,255,0.8) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0.95)), color-stop(38%,rgba(255,255,255,0.8)), color-stop(100%,rgba(255,255,255,0.8))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0.95) 0%,rgba(255,255,255,0.8) 38%,rgba(255,255,255,0.8) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(255,255,255,0.95) 0%,rgba(255,255,255,0.8) 38%,rgba(255,255,255,0.8) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(255,255,255,0.95) 0%,rgba(255,255,255,0.8) 38%,rgba(255,255,255,0.8) 100%); /* IE10+ */
        background: linear-gradient(to bottom, rgba(255,255,255,0.95) 0%,rgba(255,255,255,0.8) 38%,rgba(255,255,255,0.8) 100%); /* W3C */
        border: 2px solid #fff;
        border-radius: 10px;
        margin: 0px 0px 20px 0px;
        padding: 0px 0px 0px 0px;

        &.bear {
            background: #333;

            .panelContent, .formCodes {
                > * {
                    .error, .error * {
                        color: lighten(red,30%);
                    }

                    &:not(.error) {
                        &:first-child {
                            margin-top: 30px;
                        }

                        margin-bottom: 30px;
                    }
                }
            }

            .codeGenerated {
                text-align: center;
                font-size: 24px;
            }
        }

        .panelContent {
            padding: 20px 15px 5px 15px;

            h1, h2, h3, h4, p, ul, ol {
                color: $color-dark-blue;
            }

            h2, h3 {
                font-size: 1.75em;
                line-height: 1.2em;
                text-transform: uppercase;
                margin: 5px 0px 10px 0px;
            }

            h3 {
                font-size: 1.45em;
            }

            p, ul, ol {
                font-size: 1em;
                margin: 5px 0px 12px 0px;

                &.m-auto {
                    margin-left: auto;
                    margin-right: auto;
                }

                &.smallText {
                    font-size: 0.8em;
                    line-height: 1.25em;
                }
            }

            h2, h3 {
                &:first-child {
                    margin-top: 0px;
                }
            }
        }


        &.attractionPanel {
            .panelContent {

                .attractionLogo {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;

                    a {
                        display: block;
                        width: 100%;

                        img {
                            display: block;
                            margin: -30px auto 0 auto;
                            width: 190px;
                        }
                    }
                }

                .attractionImage {
                    margin-bottom: 10px;

                    &.rotate1 {
                        @include transform(rotate(-2deg));
                    }

                    &.rotate2 {
                        @include transform(rotate(2deg));
                    }

                    img {
                        box-shadow: rgba(0,0,0,0.3) 2px 2px 7px;
                    }
                }
            }
        }

        &.attractionPanel.atr {
            .panelContent {
                .attractionLogo a img {
                    margin: -35px auto 0 auto;
                    width: 140px;
                }
            }
        }

        &.attractionPanel.cwoa {
            .panelContent {
                .attractionLogo a img {
                    margin: -35px auto 0 auto;
                    width: 170px;
                }
            }
        }
    }
    /*
Footer layout
------------------
*/
    /* Footer menu */
    footer dl.footerLinksColumn {
        margin: 0 0 10px 0;
        padding: 12px 0 0;

        dt {
            font-weight: bold;
            margin: 0;
            padding: 0 0 5px;
        }

        dd {
            margin: 0 0 3px 0;
            padding: 0;
        }
    }

    .bear {
        #footerWrapper {
            background: transparent;
        }
    }

    #footerWrapper {
        width: 100%;
        color: $color-white;
        background: $footer-bg-color;

        #footerDecoration {
            width: 100%;

            footer {
                margin: 0px 0px 0px 0px;
                padding: 20px 15px 5px 15px;
                @include user-select(none);

                p {
                    color: #838383;
                    font-size: 16px;
                    line-height: 22px;
                    margin: 10px 0px 10px 0px;

                    &.smallText {
                        font-size: 12px;
                        line-height: 16px;
                        margin: 25px 0px 50px 0px;
                        width: 85%;
                    }
                }

                a {
                    color: #838383;
                    display: block;
                    text-decoration: none;

                    &:hover {
                        color: $color-white;
                        text-decoration: underline;
                    }
                }

                .divider {
                    display: none;
                }
            }

            .merlinLogoArea {
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 220px;
            }
        }
    }
    /*
Step-by-step guide styles 
---------------------------
*/
    .circlePanel {
        position: relative;
        background: url(/Content/images/redeem-your-code/circle.png) no-repeat center center;
        background-size: 750px;
        height: 800px;
        margin: 0;
        padding: 0;

        &.circlePanelOne {
            margin: 0;

            .orbImages {
                position: relative;

                img {
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    vertical-align: middle;
                    width: 60px;
                }

                .krave {
                    margin: 0 10px 0 0;
                }

                .specialK {
                    margin: 0 10px 0 0;
                }

                .winders {
                    margin: 0;
                }
            }
        }

        &.circlePanelTwo {
            margin: 0;

            .orbImages {
                position: relative;

                img {
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    vertical-align: middle;
                }

                .kraveOne {
                    margin: 0;
                    width: 50px;
                }

                .kraveTwo {
                    margin: 0;
                    width: 70px;
                }

                .kravePackaging {
                    margin: 0;
                    width: 120px;
                }
            }
        }

        &.circlePanelThree {
            margin: 0;

            .orbImages {
                position: relative;

                img {
                    display: inline-block;
                    width: 100px;
                    margin: 0;
                    padding: 0;
                    vertical-align: bottom;
                }

                .mtOrb {
                    margin: 0;
                }

                .shrekOrb {
                    margin: 0;
                }

                .atrOrb {
                    margin: 0;
                }
            }
        }
    }

    .circlePanelContent {
        text-align: center;
        display: block;
        padding: 130px 10px 0;
        margin: 0 auto 0 auto;
        width: auto;

        h2 {
            font-size: 22px;
            margin: 35px 0 5px;
        }

        h3 {
            font-size: 18px;
            margin: 0 0 15px 0;
        }

        p {
            font-size: 16px;
        }
    }

    p#bpAlert {
        background-color: #006f9a;
        padding: 5px 10px;
        border: 0px solid #c8008d;
        border-radius: 5px;
    }
    /*
asda promo styles
---------------------------
*/
    body.homepage.asda {
        h1 {
            margin-top: 10px;
            text-shadow: -3px -3px 0 #602e67, 1px -3px 0 #602e67, -3px 3px 0 #602e67, 3px 3px 0 #602e67;
        }

        h1, h2, h3 {
            font-family: $font-heading-asda;
        }

        h2 a {
            text-decoration: none;
        }

        #headerWrapper {
            display: none;
        }

        #contentWrapper {
            padding-top: 0px;
        }
    }
    /*
asda promo styles
---------------------------
*/
    .pringleOrb {
        display: block;
        margin: auto;
    }
    /*
Short Breaks
---------------------------
*/
    .breaksPolaroid {
        margin: 0px auto 20px;
        display: block;

        &.right {
            transform: rotate(5deg);
        }

        &.left {
            transform: rotate(-5deg);
        }
    }

.logosContainer {
    display: inline-block;
    background: rgba(255, 255, 255, 0.25);
    padding: 5px;
    border-radius: 5px;

    .shortBreakLogo {
        display: inline-block;
        width: 55px;
        margin: 4px 2px;
        padding: 2px;
        border-radius: 5px;

        &.atr {
            background: $attr-atr-bg;
        }

        &.cwoa {
            background: $attr-cwoa-bg;
        }

        &.llw {
            background: $attr-llw-bg;
        }

        &.tp {
            background: #5436AE;
        }

        &.wc {
            background: $attr-wc-bg;
        }
    }
}

    .hotelLogoContainer {
        margin: 0px auto;
        text-align: center;

        img {
            width: 150px;
            margin: 10px;
        }
    }
    /* Competition styles */
    #win-flash {
        display: none;
    }

    @media only screen and (max-width : 980px) {
        #now-open-flash {
            display: none;
        }
    }

    #prize-benefits-list {
        li {
            &:after {
                display: none;
            }

            i {
                padding: 5px;
                width: 24px;
                text-align: center;
            }
        }
    }
    /* Cookie Bar */
    #cookieBarArea {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        text-align: right;
        z-index: 10000;
    }

    #cookieBar {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background: #363636;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        -moz-box-shadow: 0px 0px 10px #000;
        -webkit-box-shadow: 0px 0px 10px #000;
        box-shadow: 0px 0px 10px #000;

        #cookieBarText {
            max-width: initial;
            margin: 8px 15px;
            font-weight: 600;
            color: #fff;
        }
    }

    #cookieBar #cookieBarText a, #cookieBar #cookieBarText a:visited {
        color: #e9dbbb;
        text-decoration: underline;
    }

    #cookieBar #cookieBarText a:hover {
        color: #fff;
    }

    .sectionWrapper.featurePanel.mapBG {
        background: #29377e;
        padding: 35px 0px 35px 0px;
    }
    /* Slider Owl Carousel */
    /**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
    /*
 * 	Default theme - Owl Carousel CSS File
 */
    .owl-carousel.home-slider {

        figure {
            &:before, &:after {
                content: "\0020";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
            }

            @media(min-width:768px) {
                &:after {
                    background: rgb(127,26,88);
                    background: linear-gradient(90deg, rgba(69,29,92,.5) 0%, rgba(0,0,0,0) 7%, rgba(0,0,0,0) 93%, rgba(69,29,92,.5) 100%);
                    z-index: 2;
                }

                &:before {
                    background: linear-gradient(45deg, rgba(0,0,0,0.855) 0%,rgba(229,229,229,0) 100%);
                    z-index: 3;
                }
            }
        }

        figure {
            position: relative;
            width: 100%;

            @media(min-width:768px) {
                max-height: 100vh;
            }
        }

        figcaption {
            position: relative;
            z-index: 4;
            text-align: left;
            width: 85%;
            margin: auto;
            margin-bottom: 22px;

            @media(min-width:768px) {
                position: absolute;
                margin-bottom: 0;
                left: 16.56%;
                bottom: 16.56%;
                max-width: 33.3333333333%;
            }
        }

        .owl-nav [class*='owl-'] {
            color: #FFF;
            font-size: 59px;
            outline: 0;
            background: 0;

            @media(min-width:768px) {
                font-size: 95px;
            }

            padding: 10px;
            cursor: pointer;
            position: absolute;
            transition: 300ms all ease;
        }

        .owl-nav [class*='owl-']:hover {
            color: #CCC;
            text-decoration: none;
        }

        .owl-nav {
            position: absolute;
            top: 36.33333333%;
            width: 100%;
            left: 0;
            transform: translate3d(0,-50%,0);
            z-index: 3;

            @media(min-width:768px) {
                top: 50%;
            }
        }

        .owl-prev {
            left: 1.75%;
        }

        .owl-next {
            right: 1.75%;
        }

        figcaption > * {
            opacity: 0;
            transform: translate3d(0,10vh,0);
        }

        .owl-item {

            &.active {
                @for $i from 1 to 5 {
                    figcaption > *:nth-child(#{$i}) {
                        transition-delay: $i * 0.14s;
                    }
                }

                figcaption > *:nth-child(1) {
                    transition-delay: 1.4s;
                }

                figcaption > *:nth-child(2) {
                    transition-delay: 1.55s;
                }

                figcaption > *:nth-child(3) {
                    transition-delay: 1.65s;
                }

                figcaption > * {
                    transform: translate3d(0,0,0);
                    opacity: 1;
                    transition: 625ms transform cubic-bezier(0.21, 0.01, 0, 1), 220ms opacity linear;
                }
            }
        }
    }

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

    @-webkit-keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    .covid {
        padding: 40px;
        background: #FFD636;
        color: #000000 !important;
        border-radius: 20px;

        @media(min-width:1200px) {
            margin: 0 100px;
        }
    }

    .covid a, covid a:hover, covid a:visited, covid a:focus {
        color: #000 !important;
        text-decoration: underline;
    }

    .covid p {
        color: #000 !important;
    }

    .expander-content {
        display: none;
    }

    .expander-content.show {
        display: block;
    }

    .covid button {
        background: #FFD636;
        cursor: pointer;
        color: #6d09c2;
        margin-bottom: 10px;
        border: none;
        font-size: 0.75em;

        @media(min-width:768px) {
            font-size: 1em;
        }
    }

    .covid button:hover {
        color: #98408d;
        text-decoration: underline;
    }

    .covid button:focus {
        outline: none;
    }

    @media(min-width:1584px) {
        .panelContent.half-width {
            display: inline-block;
            width: 45%;
        }
    }

    .panelContent h4 {
        margin-top: 40px;
    }

    .legolandWindsor h4 {
        color: #272A94 !important;
    }

    .infoText {
        margin-bottom: 35px !important;
    }

    .flowSection.complete .infoText {
        display: none;
    }

    .covid-terms {
        padding: 40px;
        background: #d80019;
        color: #000000 !important;
        border-radius: 20px;
        margin: 20px 0px;
    }

    .covid-terms p {
        color: #fff !important;
    }

.sl-terms {
    padding: 40px;
    background: #282b91;
    color: #000000 !important;
    border-radius: 20px;
    margin: 20px 0px;
}

.sl-terms p {
    color: #fff !important;
}

.ripped-bg {
    -webkit-mask-image: url('/Content/images/rip-bg/top-mask.svg'), url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=), url('/Content/images/rip-bg/bottom-mask.svg');
    -webkit-mask-position: center top, 0 10px, center bottom;
    -webkit-mask-size: 500px 11px, 100% calc(100% - 20px), 500px 11px; /* 11px allows for 1px overlap */
    -webkit-mask-repeat: repeat-x, no-repeat, repeat-x;
    mask-image: url('/Content/images/rip-bg/top-mask.svg'), url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=), url('/Content/images/rip-bg/bottom-mask.svg');
    mask-position: center top, 0 10px, center bottom;
    mask-size: 500px 11px, 100% calc(100% - 20px), 500px 11px;
    mask-repeat: repeat-x, no-repeat, repeat-x;
}

.ripped-bg {
    width: auto;
    padding: 80px 30px;
    margin: -10px -20px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    figure img {
        width: 100%;
    }

    .content {
        text-decoration: none;
        color: #fff700;
        text-align: center;
        padding: 0 10%;
        margin: 30px 0 0 0;
        font-size: 24px;
        font-weight: 400;

        &:hover {
            text-decoration: underline;
            color: white;
        }
    }

    + div {
        margin-top: -10px;
    }
}
.formTerms{
    font-size:15px!important;
}